import { useState } from 'react'
import { PropTypes } from 'prop-types'
import { ArrowDown, ArrowUp } from '@jsluna/icons'
import { LinkButton, TextButton } from '@jsluna/button'

function ChildAssetsDetails({ childAssets, siteName }) {
  const [numberOfChildAssetsShown, setNumberOfChildAssetsShown] = useState(10)
  const [isCollapsed, setIsCollapsed] = useState(true)

  const ShowMoreAssets = () => {
    setNumberOfChildAssetsShown(
      numberOfChildAssetsShown + 10 <= childAssets.length
        ? numberOfChildAssetsShown + 10
        : childAssets.length,
    )
  }
  childAssets.sort((a, b) => (a.fixtureNumber > b.fixtureNumber ? 1 : -1))

  return childAssets?.length > 0 ? (
    <div id="r-childAssetDetail">
      <TextButton
        id="r-showChild-button"
        fullWidth
        className={`${!isCollapsed ? 'ln-u-visually-hidden' : null}`}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {isCollapsed ? <ArrowDown /> : null}
      </TextButton>
      <div
        id="r-childAssets"
        className={`${isCollapsed ? 'ln-u-visually-hidden' : null}`}
        aria-expanded={!isCollapsed}
      >
        The following cabinets are associated to the Multiple fixture alarm:
        <ol id="r-childAssetList">
          {childAssets
            ?.slice(
              0,
              numberOfChildAssetsShown > childAssets.length
                ? childAssets.length
                : numberOfChildAssetsShown,
            )
            .map((asset, index) => (
              <li id="r-childAssetList-item" key={index}>
                {siteName.startsWith('P')
                  ? `${asset.fixtureNumber} (PFS)`
                  : asset.fixtureNumber}
                {asset.assetNumber ? `, #${asset.assetNumber}` : null}
                {asset.assetTypeDescription
                  ? `, ${asset.assetTypeDescription}`
                  : null}
              </li>
            ))}
          {numberOfChildAssetsShown < childAssets.length ? (
            <LinkButton onClick={() => ShowMoreAssets()}>
              ...show more
            </LinkButton>
          ) : null}
        </ol>
      </div>
      <TextButton
        id="r-hideChild-button"
        fullWidth
        className={`${isCollapsed ? 'ln-u-visually-hidden' : null}`}
        onClick={() => {
          setIsCollapsed(!isCollapsed)
          setNumberOfChildAssetsShown(10)
        }}
      >
        {isCollapsed ? null : <ArrowUp />}
      </TextButton>
    </div>
  ) : null
}

ChildAssetsDetails.propTypes = {
  childAssets: PropTypes.shape().isRequired,
  siteName: PropTypes.string.isRequired,
}

export default ChildAssetsDetails
