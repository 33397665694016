import { Body1 } from '@jsluna/typography'
import getRelativeDate from '../../../utils/relativeDate'

interface AlarmHistoryLogParams {
  alarm: {
    dateOpened: string
    isClosed: boolean
    closedTimestamp: string
    demerchandisedEvents: [{
      timestamp: string,
      user: string,
    }],
    respondedEvents: [{
      timestamp: string,
      user: string,
    }],
  }
  modelType?: string,
}

const AlarmHistoryLog = ({alarm, modelType}: AlarmHistoryLogParams) => {
  const getAcknowledgeMessage = (timestamp: string, user: string) => {
    return user.includes('Verisae User')
      ? `Acknowledged in Verisae on ${getRelativeDate(timestamp)} by ${user}`
      : `Acknowledged on ${getRelativeDate(timestamp)} by ${user}`
  }

  const getDemerchandiseMessage = (timestamp: string, user:string) => {
    return `Demerchandised on ${getRelativeDate(timestamp)} by ${user}`
  }
  const showAlarmPageEvents = () => {
    const firstEvent =
      alarm.respondedEvents != null && alarm.respondedEvents.length > 0
        ? alarm.respondedEvents[0]
        : null
    return (
      <div>
        {firstEvent != null && (
          <div>
            <li>
              {' '}
              {getAcknowledgeMessage(firstEvent.timestamp, firstEvent.user)}
            </li>
          </div>
        )}
      </div>
    )
  }
  const showAlarmHistoryEvents = () => {
    return (
      <div>
        {alarm.respondedEvents != null && alarm.respondedEvents.length > 0 && (
          <div>
            {alarm.respondedEvents.map((event, index) => (
              <li key={index}> { getAcknowledgeMessage(event.timestamp, event.user)}</li>
            ))}
          </div>
        )}
        {alarm.demerchandisedEvents != null &&
          alarm.demerchandisedEvents.length > 0 && (
            <div>
              {alarm.demerchandisedEvents.map((event, index) => (
                <li key={index}> {getDemerchandiseMessage(event.timestamp, event.user)}</li>
              ))}
            </div>
          )}
        {alarm.isClosed ? (
          <li id="r-tag-closingDate">
            Returned to normal on {getRelativeDate(alarm.closedTimestamp)}
          </li>
        ) : null}
      </div>
    )
  }

  return (
    <Body1 className="ln-u-color-grey">
      <ul className="ln-u-flush-bottom">
        <li id="r-tag-reportingDate">
          Reported on {getRelativeDate(alarm.dateOpened)}
        </li>
        {modelType === 'alarmHistory'
          ? showAlarmHistoryEvents()
          : showAlarmPageEvents()}
      </ul>
    </Body1>
  )
}

export default AlarmHistoryLog
