import { ReactNode, createContext, useContext } from 'react';
import { useMsal } from "@azure/msal-react";
import { ApiClient } from "../api/ApiClient";
import { IStore } from '../types/Store/IStore';
import { IDetails } from "../types/Store/IStoreDetails";
import { IAssetsDetails } from '../types';

export interface AppContextData { 
    store?: IStore | undefined;
    page: { [key: string]: number };
    search?: string | null;
}

export type AppContextType = {
    apiClient: ApiClient;
    apiScope: string;
    tutorialServiceScope: string;
    storeList?: IDetails[] | undefined;
    assets: { [key: string]: IAssetsDetails | undefined };
    appContextData: AppContextData;
    setData: (property: any, value: any) => void;
    setSpecificData: (property: any, key: any, value: any) => void;
    resetData: () => void;
};

export const AppContext = createContext<AppContextType | null>(null);

type AppProviderProps = {
    apiScope: string;
    tutorialServiceScope: string;
    apiBaseUrl: string;
    store?: IStore | undefined;
    storeList?: IDetails[] | undefined;
    page: { [key: string]: number };
    search?: string | null;
    siteCommunicationAlarm: { [key: string]: any };
    siteAlarmHistory: { [key: string]: any };
    assets: { [key: string]: IAssetsDetails | undefined };
    children: ReactNode;
};

export const AppProvider: React.FC<AppProviderProps> = ({apiScope, tutorialServiceScope, apiBaseUrl, store, search, page, assets, children}) => {
    const {instance} = useMsal();
    const apiClient = new ApiClient(
        instance,
        apiScope,
        tutorialServiceScope,
        apiBaseUrl
    );
    let appContextData: AppContextData = {
        store,
        page,
        search
    };

    const storage = sessionStorage.getItem('AppContextData');
    if (storage){
        const contextData: AppContextData = JSON.parse(storage)
        appContextData = contextData
    }

    const setData = (property: keyof AppContextData, value: any) => {
        if (Object.prototype.hasOwnProperty.call(appContextData, property)) {
            appContextData[property] = value;
            sessionStorage.setItem('AppContextData', JSON.stringify(appContextData));
        } else {
            console.error(`Property ${property} does not exist on appContext.`);
        }
    };

    const resetData = () => {
        const page = {
            alarmOverviewPage: 0,
            alarmHistoryPage: 0,
            fixturesPage: 0,
        }
        appContextData.search = null
        appContextData.page = page
        sessionStorage.setItem('AppContextData', JSON.stringify(appContextData));
    };

    const setSpecificData = (property: string, key: any, value: any) => {
        switch (property) {
            case 'page':
                appContextData.page[key] = value
                sessionStorage.setItem('AppContextData', JSON.stringify(appContextData));
                break;
            default:
                null
        }
    }

    return <AppContext.Provider value={{ apiClient, apiScope, tutorialServiceScope, appContextData, assets, setData, setSpecificData, resetData }}> { children } </AppContext.Provider>
}

export const useApiClient = () => {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error("useApiClient must be used within an AppProvider");
    }

    return context.apiClient;
}