import PackAlarmResolved from './PackAlarmResolved'
import FixtureCommunicationAlarmResolved from './FixtureCommunicationAlarmResolved'
import MultipleFixtureCommunicationAlarmResolved from './MultipleFixtureCommunicationAlarmResolved'

function Index(data) {
  return (
    <div id="r-resolved-alarm-events">
      {data.alarmClosureDetails.isPackAlarmClosedRecently ? (
        <PackAlarmResolved />
      ) : null}
      {data.alarmClosureDetails.isFixtureCommunicationAlarmClosedRecently ? (
        <FixtureCommunicationAlarmResolved />
      ) : null}
      {data.alarmClosureDetails
        .isMultipleFixtureCommunicationAlarmClosedRecently ? (
        <MultipleFixtureCommunicationAlarmResolved />
      ) : null}
    </div>
  )
}

export default Index
