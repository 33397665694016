import { useContext, useState } from "react";
import { AppContext } from "../context/AppContext";

const usePage = (pageType: string) => {

  const context = useContext(AppContext)
  const [page, setPage] = useState<number>(context && context.appContextData.page[pageType] ? context.appContextData.page[pageType] : 0)

  const handlePage = (pageType: string, pageNumber?: number) => {
    if (context && pageNumber !== undefined && pageNumber >= 0) {
      setPage(pageNumber)
      context.setSpecificData('page', pageType, pageNumber)
    }
  }

  return {
    page,
    handlePage
  } 
}

export default usePage;