import { Container } from '@jsluna/react'
import { Card } from '@jsluna/card'
import { SearchField } from '@jsluna/form'
import { SearchButton } from '@jsluna/button'
import { Body1, Display3 } from '@jsluna/typography'
import FixtureList from '../components/FixturesList'
import Scanner from '../components/Scanner'
import useSearch from '../hooks/useSearch'
import { useState } from 'react'
import { ErrorCircle } from '@jsluna/icons'

const FixturesPage = () => {
  const {inputText, searchQuery, handleInputText, handleSearchQuery, setInputText} = useSearch()
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const getBarcode = (barcode: string) => {
    if (barcode.length > 30) {
      setErrorMessage("Barcode should not exceed maximum length of 30 characters")
      setIsError(true)
    }
    else{
      handleInputText(barcode)
      setInputText(barcode)
      handleSearchQuery(barcode)
    }
  }

  const checkCharacterLimit = () => {
    if (inputText.length >= 3 && inputText.length <= 51) {
      handleInputText(inputText)
      handleSearchQuery(inputText)
    }
    else {
      setErrorMessage("Search field should have minimum length of 3 and maximum length of 51. Please try again.")
      setIsError(true)
    }
  }

  return (
    <>
      <Container className="r-pageName ln-u-padding-ends*2" size="xs">
        <Display3 id="r-page-title" className="r-page-title">
          Fixture list
        </Display3>
      </Container>

      <Container className="r-searchBar ln-u-soft-bottom" size="xs">
        <Card id="r-searchBar-card">
          <div className="ln-u-soft-bottom">
            <Body1>
              View fixture details and status, or amend product type.
            </Body1>
          </div>
          <SearchField
            id="r-searchField"
            name={"r-searchField"}
            className="r-searchField"
            placeholder="Search assets"
            hasButton
            minLength={3}
            maxLength={51}
            value={inputText}
            onChange={ (e: any)  => {
              handleInputText(e.target.value)
              setInputText(e.target.value)
              setIsError(false)
              if (e.target.value === '')
              {
                handleInputText(e.target.value)
                setInputText(e.target.value)
                handleSearchQuery(e.target.value)
              }
            }}
            onKeyPress={ (e: any) =>
              e.key === 'Enter' ? checkCharacterLimit() : null
            }
            button={
              inputText === '' ? (
                <div>
                  <Scanner
                    passBarcode={getBarcode}
                    id="r-scanner-button"
                    className="r-scanner"
                  />
                </div>
              ) : (
                <SearchButton
                  id="r-search-button"
                  onClick={() => checkCharacterLimit()} 
                />
              )
            }
          />
          {isError ?
          <div>
            <ErrorCircle
              id="r-search-error"
              className="r-error-icon ln-u-color-red ln-u-display-1"
            />
            <span id="r-search-error-text" style={{color:'red', fontWeight:'bold'}}>
              {errorMessage}
            </span>
          </div>
          : null}
          
        </Card>
      </Container>

      <Container className="r-assetsList" size="xs">
        <FixtureList searchQuery={searchQuery} />
      </Container>
    </>
  )
}

export default FixturesPage