import PropTypes from 'prop-types'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'

function Loading({ message }) {
  return (
    <ProgressIndicator
      className="ln-u-justify-content-center"
      loading
      preventFocus
    >
      <p className="a-loading-mesage">
        <ProgressSpinner size="medium" className="ln-u-push-right-sm" />
        {message}
      </p>
    </ProgressIndicator>
  )
}

Loading.propTypes = {
  message: PropTypes.string,
}

Loading.defaultProps = {
  message: null,
}

export default Loading
