import { ApiClient } from './ApiClient'
import { IApiRequest, IGetApiRequest } from '../types/IWebApiRequest'
import withQuery from '../utils/with-query'

const get = async <T>(
  apiClient: ApiClient,
  req: IGetApiRequest
): Promise<T> => {
  const encoded = withQuery(req.params)
  const fullUrl = encoded ? `${req.url}?${encoded}` : req.url

  return await apiClient.get(fullUrl).then(res => res as T)
}

const post = async (apiClient: ApiClient, req: IApiRequest) => {
  const encoded = req.params.map(n => encodeURI(n)).join('/')
  const fullUrl = req.params.length ? `${req.url}/${encoded}` : req.url

  return await apiClient.post(fullUrl, req.body).then(res => res)
}

const put = async <T>(apiClient: ApiClient, req: IApiRequest): Promise<T> => {
  const encoded = req.params.map(n => encodeURI(n)).join('/')
  const fullUrl = req.params.length ? `${req.url}/${encoded}` : req.url

  return await apiClient.put(fullUrl, req.body).then(res => res as T)
}

const deleteImage = async (apiClient: ApiClient, url: string) => {
  return await apiClient.delete(url).then(res => res)
}

const uploadFiles = async (
  apiClient: ApiClient,
  url: string,
  data: FormData
) => {
  return await apiClient.uploadFiles(url, data).then(res => res)
}

const webApi = {
  get,
  post,
  put,
  deleteImage,
  uploadFiles
}

export default webApi
