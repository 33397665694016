import { useState } from 'react'
import { PropTypes } from 'prop-types'
import BarcodeScanner from '@jsainsburyplc/retail-barcode-scanner'
import { IconButton } from '@jsluna/button'
import { Scan } from '@jsluna/icons'

function Scanner({ passBarcode }) {
  const [isScanning, setIsScanning] = useState(false)

  const onScan = ({ code }) => {
    setIsScanning(false)
    passBarcode(code)
  }

  const onCancel = () => {
    setIsScanning(false)
  }

  if (isScanning)
    return (
      <BarcodeScanner
        formats={['ean', 'ean_8']}
        onCancel={onCancel}
        onScan={onScan}
      />
    )

  return (
      <IconButton
      id="a-barcodeScanner-button"
      className="ln-u-hard"
      variant="filled"
      label="Barcode scanner"
      hideLabel
      onClick={() => {
        setIsScanning(true)
      }}
      >
        <Scan />
      </IconButton>
  )
}

Scanner.propTypes = {
  passBarcode: PropTypes.func.isRequired,
}

export default Scanner
