import { PropTypes } from 'prop-types'
import { Card } from '@jsluna/card'
import { Body2 } from '@jsluna/typography'
import { Account } from '@jsluna/icons'
import ProductTypeChangeDetails from '../ExtraElements/ProducttypeChangeDetails'
import Timestamp from '../ExtraElements/Timestamp'

function ProductTypeChange({ event }) {
  return (
    <div className="r-event-product-type-change-request ln-u-padding-bottom">
      <Card>
        <div id="r-event-header" className="r-event-header ln-u-soft-bottom-sm">
          <Body2 className="ln-u-font-weight-bold">{event.eventName}</Body2>
        </div>
        {!event.newProductType == null ? null : (
          <ProductTypeChangeDetails event={event} />
        )}
        <Timestamp timestamp={event.timestamp} />
        {event.user == null ? null : (
          <div id="r-event-user" className="r-event-user-name">
            <Account /> {event.user}
          </div>
        )}
      </Card>
    </div>
  )
}

ProductTypeChange.propTypes = {
  event: PropTypes.shape({
    eventName: PropTypes.string,
    timestamp: PropTypes.string,
    user: PropTypes.string,
    newProductType: PropTypes.string,
    currentProductType: PropTypes.string,
  }).isRequired,
}

export default ProductTypeChange
