import { useContext, useState } from "react";
import { AppContext } from "../context/AppContext";

const useSearch = () => {
    const context = useContext(AppContext)
    const [inputText, setInputText] = useState<string>(context && context?.appContextData.search ? context.appContextData.search : '')
    const [searchQuery, setSearchQuery] = useState<string>(context && context?.appContextData.search ? context.appContextData.search : '')
  
    const handleInputText = (searchQuery:string) => {
      if (context && searchQuery !== null) {
        context.setData('search', searchQuery)
      }
    }

    const handleSearchQuery = (searchQuery:string) => {
      if (context && searchQuery !== null) {
        setSearchQuery(searchQuery)
      }
    }

    return {
        inputText, searchQuery, handleInputText, handleSearchQuery, setInputText
    }
  }
  
  export default useSearch;