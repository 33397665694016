import { Card } from '@jsluna/card'
import { CheckboxField } from '@jsluna/form'
import { Display3 } from '@jsluna/typography'
import { FilledButton, Button } from '@jsluna/button'

interface FilteringPanelParams {
  filters: any,
  filtersList: any,
  handleFilters: any,
  passOpenFilterPanel?: (value:boolean) => void,
}

const FilteringPanel = ({filters, passOpenFilterPanel, handleFilters, filtersList}: FilteringPanelParams) => {
  const updateFiltersList = ( target: any ) => {
    handleFilters(filters, "update", target)
  }

  const filterAlarms = async () => {
    if (passOpenFilterPanel) passOpenFilterPanel(false)
  }

  const clearFilters = () => {
    handleFilters(filters, "reset")
    if (passOpenFilterPanel) passOpenFilterPanel(false)
  }

  const checkFilterStatus = (value: any) => {
    return !!filters?.some((el: any) => el.filterValue === value)
  }

  return (
    <>
    <Card id="r-filterBar">
      <Display3
        className="r-filter-header-desktop"
        id="r-filter-header-desktop"
      >
        Filters
      </Display3>
      {Object.keys(filtersList).map( (data: string) => (
        <CheckboxField key={data}
          options={filtersList[data].map((filter: {[key:string]: { value: any; label: any }}) => ({
            value: filter.value,
            label: filter.label,
            checked: checkFilterStatus(filter.value),
          }))}
          id="r-filter-checkbox-field"
          label={data}
          name="checkbox-field-filters"
          outlined
          fullWidth
          onChange={(e: any) => updateFiltersList(e)}
        />
      ))}

      <FilledButton
        id="r-filters-apply-button"
        fullWidth
        onClick={() => filterAlarms()}
      >
        Apply
      </FilledButton>
      <Button
        id="r-filters-clear-button"
        className="ln-u-margin-ends*2"
        fullWidth
        variant="outlined"
        disabled={filters !== undefined && filters.length === 0}
        onClick={() => clearFilters()}
      >
        Clear filters
      </Button>
    </Card>
    </>
  )
}

export default FilteringPanel
