import { useEffect, useState } from 'react'
import assetsApi from '../../../api/assetsApi'
import { SelectField } from '@jsluna/form'
import { FilledButton } from '@jsluna/button'
import { ProgressSpinner } from '@jsluna/progress'
import { Container } from '@jsluna/react'
import { Card } from '@jsluna/card'
import { Body2, Display1, Caption } from '@jsluna/typography'
import Error from '../../Error'
import countDifferenceInDays from '../../../utils/countDifferenceInDays'
import FailingMessage from './FailingMessage'
import InProgressMessage from './InProgressMessage'
import RejectionMessage from './RejectionMessage'
import CompletedMessage from './CompletedMessage'
import { useApiClient } from '../../../context/AppContext'
import useMerchandisingStatus from '../../../hooks/useMerchandisingStatus'
import { IMerchandisingStatus } from '../../../types'
import ChangeIsBlockedMessage from './ChangeIsBlockedMessage'

interface ChangeMerchandiseFormParams {
  isAlarmed: boolean | null | undefined
  merchandisingDetails: {
    siteName: string
    assetNumber: string
    fixtureNumber: string
    productType: string
  }
  productTypeChangeDetails?: {
    status: string
    reason: string
    timestamp: Date
  }
}

const ChangeMerchandiseForm = ({
  isAlarmed,
  merchandisingDetails,
  productTypeChangeDetails
}: ChangeMerchandiseFormParams) => {
  const apiClient = useApiClient()
  const [requestInProgress, setRequestInProgress] = useState(
    productTypeChangeDetails?.status === 'REQUESTED'
  )
  const [requestFailed, setRequestFailed] = useState(
    productTypeChangeDetails?.status === 'FAILED'
  )
  const [requestIsCompleted] = useState(
    productTypeChangeDetails?.status === 'PROCESSED'
  )
  const [requestIsRejected] = useState(
    productTypeChangeDetails?.status === 'REJECTED'
  )
  const [newProductType, setNewProductType] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const [requestDetails, setRequestDetails] = useState<{
    currentProductType: string
    newProductType: string
    timestamp: Date
  }>()
  const [merchandisingStatus, setMerchandisingStatus] = useState<
    IMerchandisingStatus[] | undefined
  >()

  const [response] = useMerchandisingStatus(merchandisingDetails.assetNumber)

  useEffect(() => {
    setMerchandisingStatus(response?.data)
  }, [response])

  const sendProductTypeChangeRequest = async () => {
    setIsButtonDisabled(true)

    const postProductTypeResponse = await assetsApi.PostProductTypeRequest(
      apiClient,
      merchandisingDetails,
      newProductType
    )

    if (postProductTypeResponse.error) {
      setErrorMessage(postProductTypeResponse.error)
      setRequestFailed(true)
    } else {
      setRequestInProgress(true)
    }

    setIsButtonDisabled(false)
    setRequestDetails({
      currentProductType: merchandisingDetails.productType,
      newProductType,
      timestamp: new Date()
    })
  }

  const CheckProductTypeChangesAndSendResponse = async () => {
    if (newProductType === null || newProductType === '')
      setErrorMessage('Please select a new product type')
    else if (merchandisingDetails.productType === newProductType)
      setErrorMessage(
        'New product type is same as current product type. Please select another product type'
      )
    else await sendProductTypeChangeRequest()
  }

  const under5DaysOld =
    countDifferenceInDays(productTypeChangeDetails?.timestamp) < 5
  const hasRequestDetails =
    requestDetails !== null && requestDetails !== undefined
  const showFailingMessage =
    (errorMessage !== '' && hasRequestDetails) ||
    (requestFailed && under5DaysOld)
  const showRejectionMessage =
    !hasRequestDetails && requestIsRejected && under5DaysOld
  const showCompletedMessage =
    !hasRequestDetails && requestIsCompleted && under5DaysOld
  const showBlockedMessage =
    isAlarmed &&
    !(
      (errorMessage !== '' && requestDetails !== null) ||
      (requestFailed && under5DaysOld) ||
      (requestDetails === null && requestIsRejected && under5DaysOld) ||
      (requestDetails === null && requestIsCompleted && under5DaysOld)
    )

  if (response.status === 'busy') {
    return (
      <div className="ln-u-text-align-center ln-u-padding-top*4">
        <ProgressSpinner />
      </div>
    )
  }

  if (response.error && response.status === 'failure' && !merchandisingStatus)
    return <Error />

  if (requestInProgress)
    return (
      <InProgressMessage
        id="r-request-in-progress-message"
        className="r-request-in-progress-message"
        productTypeChangeData={
          requestDetails != null ? requestDetails : productTypeChangeDetails
        }
      />
    )

  return (
    <Container
      id="r-change-merchandise-container"
      className="ln-u-padding-ends"
      size="xs"
    >
      <Display1>
        <div id="r-change-merchandise-display" className="ln-u-padding*2">
          Request change
        </div>
      </Display1>
      {showBlockedMessage && <ChangeIsBlockedMessage />}
      {showFailingMessage && (
        <FailingMessage
          productTypeChangeData={requestDetails || productTypeChangeDetails}
          id="r-request-failing-message"
          className="r-request-failing-message"
        />
      )}
      {showRejectionMessage && <RejectionMessage />}
      {showCompletedMessage && (
        <CompletedMessage
          message={productTypeChangeDetails?.reason}
          id="r-request-completion-message"
          className="r-request-completion-message"
        />
      )}
      <Card id="r-change-merchandise-card">
        <div className="ln-u-padding">
          <Body2 className="ln-u-font-weight-bold">Current product type</Body2>
          <div
            className="ln-u-font-weight-bold ln-u-padding-ends"
            id="r-change-merchandise-product-type"
          >
            <Caption>{merchandisingDetails.productType}</Caption>
          </div>
          {merchandisingStatus && (
            <SelectField
              id="r-change-merchandise-select-product-type"
              className="ln-u-margin-top*2"
              name="select-product-type"
              label="Select new product type"
              error={errorMessage}
              options={merchandisingStatus.map((type: any) => ({
                label: type.productType,
                value: type.productType
              }))}
              onChange={(e: any) => {
                setErrorMessage('')
                setNewProductType(e.target.value)
              }}
            />
          )}
          <FilledButton
            id="r-change-merchandise-confirm-request"
            fullWidth
            disabled={isButtonDisabled}
            onClick={() => CheckProductTypeChangesAndSendResponse()}
          >
            Confirm request
          </FilledButton>
        </div>
      </Card>
    </Container>
  )
}

export default ChangeMerchandiseForm
