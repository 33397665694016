import { FilledButton } from '@jsluna/button'
import { PropTypes } from 'prop-types'
import { Container } from '@jsluna/grid'

function TryAgainButton({ tryAgain }) {
  return <FilledButton onClick={tryAgain}>Try Again</FilledButton>
}

TryAgainButton.propTypes = {
  tryAgain: PropTypes.func.isRequired,
}

function Error({ message, tryAgain }) {
  return (
    <Container className="a-page-push" id="a-error-page" soft size="xs">
      {message}
      {tryAgain && <TryAgainButton tryAgain={tryAgain} />}
    </Container>
  )
}

Error.propTypes = {
  message: PropTypes.string.isRequired,
  tryAgain: PropTypes.func,
}

Error.defaultProps = {
  tryAgain: null,
}

export default Error
