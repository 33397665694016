import { useApiClient } from '../context/AppContext'
import IResourceLoader from '../types/IResourceLoader'
import { useCallbackRef, useResourceLoader } from '.'
import { ILoadStore } from '../types/Store/ILoadStore'
import { FetchStore } from '../types/Store/FetchStore'
import storesApi from '../api/storesApi'
import { IDetails } from '../types/Store/IStoreDetails'

const useStoreLoader = (): [IResourceLoader<IDetails>, ILoadStore] => {
  const apiClient = useApiClient()

  const resource = useResourceLoader<IDetails>('Unable to load store')

  const loader = useCallbackRef<FetchStore>((code: string) =>
    resource.load(async () => storesApi.getStore(apiClient, code))
  )

  return [resource, loader]
}

export default useStoreLoader
