import { Container } from '@jsluna/react'
import { Card } from '@jsluna/card'
import { Body2, Caption } from '@jsluna/typography'
import { ErrorCircle } from '@jsluna/icons'
import { FilledButton } from '@jsluna/button'
import { useNavigate } from 'react-router-dom'

function RejectionMessage() {
  const history = useNavigate()
  return (
    <Container className="ln-u-padding-top" size="xs">
      <Card
        id="r-request-rejection-message"
        className="r-request-rejection-message ln-u-margin-bottom*2"
      >
        <div className="ln-u-text-align-center">
          <Body2
            id="r-request-rejection-header"
            className="ln-u-font-weight-bold"
          >
            <ErrorCircle
              id="r-errorCircle"
              className="r-error-icon ln-u-color-red ln-u-display-3"
            />
            <br />
            Change rejected
          </Body2>
        </div>
        <div className="ln-u-padding-ends*2">
          <Caption id="r-request-rejection-text">
            Your changed has not been made as it was not a valid change for this
            cabinet. Please try again if you need to change the products in the
            cabinet.
          </Caption>
        </div>
        <FilledButton id="r-return-button" fullWidth onClick={() => history(-1)}> 
          Back to fixture list
        </FilledButton>
      </Card>
    </Container>
  )
}

export default RejectionMessage
