const filtering = (filtersList: Array<any>, actionType: string, filter?: any): Array<any> => {
  switch (actionType) {
    case "update":
      return filter !== undefined && filter.target.checked
        ? [
            ...filtersList,
            {
              filterName: filter !== undefined && filter.target.labels[0].innerText,
              filterValue: filter !== undefined && filter.target.value,
         },
          ]
        : [...filtersList.filter( (id: any) => id.filterValue !== filter.target.value)]
    case "remove":
      return [
        ...filtersList.filter( (id: any) => id.filterValue !== filter.filterValue),
      ]
    case "reset":
      return []
    default:
      return filtersList
  }
}

export default filtering