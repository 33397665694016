import { useContext, useEffect, useState } from 'react'
import assetsApi from '../../../../api/assetsApi'
import { Card } from '@jsluna/card'
import { Container } from '@jsluna/react'
import { Caption, Display1 } from '@jsluna/typography'
import { FormGroup, Label } from '@jsluna/form'
import {
  ProgressBar,
  ProgressIndicator,
  ProgressSpinner,
} from '@jsluna/progress'
import { FilledButton } from '@jsluna/button'
import alarmsApi from '../../../../api/alarmsApi'
import getRelativeDate from '../../../../utils/relativeDate'
import ImageUpload from '../../../ImageUpload'
import ImageGuidance from '../ImageGuidance'
import WorkOrderLink from '../../WorkOrderLink/WorkOrderLink'
import { AppContext, useApiClient } from '../../../../context/AppContext'
import { IImages } from '../../../../types'
import { IAlarms } from '../../../../types/AlarmsPaginated/IAlarmsPaginated'

interface DeMerchandiseFormPararms {
  alarm: IAlarms,
  storeCode: string,
  userName: string,
  showDeMerchandise: boolean
}

const DeMerchandiseForm = ({ alarm, storeCode, userName, showDeMerchandise }: DeMerchandiseFormPararms) => {
  const [loading, setLoading] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)
  const [uploadedImages, setUploadedImages] = useState<IImages[]>()
  const [imageErrorMessage, setImageErrorMessage] = useState<string>()
  const [buttonIsDisabled, setButtonIsDisabled] = useState(
    alarm.lastResponse === 'de_merchandising',
  )
  const [errorMessage, setErrorMessage] = useState("")
  const [userResponded, setUserResponded] = useState(alarm.user)
  const [timeResponded, setTimeResponded] = useState(alarm.responseTime)
  const [showCheckCompletedMessage, setShowCheckCompletedMessage] = useState(
    alarm.lastResponse === 'de_merchandising',
  )
  const apiClient = useApiClient()
  const appcontext = useContext(AppContext)
  const getAlarmImagesURI = async () => {
    const response = await assetsApi.GetImages(
      apiClient,
      alarm.assetNumber,
      alarm.id,
      'DEMERCHANDISE',
    )

    if (response != null && response.length > 0) {
      setUploadedImages(response)
      if (
        response.length >= 3 &&
        (response?.every(image => image.isUploadedToVerisae) ||
          response?.every(image => image.timestamp <= timeResponded))
      ) {
        setButtonIsDisabled(true)
      }
    } else setUploadedImages([])
  }

  useEffect(() => {
    setLoading(true)
    getAlarmImagesURI()
    setLoading(false)
  }, [])

  const sendImages = async (images: any) => {
    setImageLoading(true)
    const imageResponse = await assetsApi.UploadFiles(
      apiClient,
      alarm.assetNumber,
      alarm.id,
      'DEMERCHANDISE',
      images,
    )
    if (imageResponse?.uploadedImagesCount > 0) {
      getAlarmImagesURI()
    }
    if (imageResponse?.error) {
      setImageErrorMessage(
        imageResponse.errorMessages ?? 'Unable to upload one or more image(s)',
      )
      setButtonIsDisabled(false)
    }
    if (appcontext && appcontext.assets[alarm.assetNumber]) {
      delete appcontext.assets[alarm.assetNumber]
    }
    setImageLoading(false)
  }

  const deleteImage = async (imageName: string) => {
    setImageLoading(true)
    const response = await assetsApi.DeleteImage(apiClient, alarm.assetNumber, alarm.id, imageName)
    if (response.error) setImageErrorMessage(response.errorMessage)
    await getAlarmImagesURI()
    setImageLoading(false)
  }

  const checkCompletedMessage = () => {
    return (
      <div className="ln-c-form-group">
        <Caption id="r-caption-responseCheck" className="ln-u-color-grey">
          Check completed by&nbsp;{userResponded} on&nbsp;
          {getRelativeDate(timeResponded)}
        </Caption>
      </div>
    )
  }
  const sendResponse = async () => {
    if (!alarm.isParentAlarmed && uploadedImages && uploadedImages.length === 0) {
      setImageErrorMessage('Please add at least 1 image')
      setButtonIsDisabled(false)
    } else {
      setLoading(true)
      const postAlarmResponse = await alarmsApi.PostAlarmResponse(
        apiClient,
        storeCode,
        userName,
        alarm,
        'confirm_action',
        'Cabinet demerchandised',
        true,
      )
      if (!postAlarmResponse?.status) {
        setErrorMessage(
          postAlarmResponse.message ?? 'Unable to submit the response',
        )
        setButtonIsDisabled(false)
      } else {
        setUserResponded(userName)
        setTimeResponded(new Date().toString())
        setShowCheckCompletedMessage(true)
        setButtonIsDisabled(true)
      }
      if (appcontext && appcontext.assets[alarm.assetNumber]) {
        delete appcontext.assets[alarm.assetNumber]
      }
      setLoading(false)
    }
  }

  const showImageUpload = () => {
    return (
      <div>
        <Label htmlFor="Upload image">Upload image</Label>
        <div className="ln-u-padding-bottom*2">
          So a remote fix can be attempted, please take a photo of the
          de-merchandised fixtures showing all bays.{' '}
          <ImageGuidance
            alarm={alarm}
            showDeMerchandise={showDeMerchandise}
          />
        </div>
        <div id="r-imageUpload">
          <ImageUpload
            uploadedImages={uploadedImages}
            buttonDisabled={buttonIsDisabled}
            errorMessage={imageErrorMessage}
            sendImages={sendImages}
            removeImage={deleteImage}
            lastResponseTime={timeResponded}
          />
          {imageLoading ? (
            <ProgressIndicator loading preventFocus>
              <ProgressBar
                color="light"
                size="small"
                className="ln-u-margin-right"
              />
            </ProgressIndicator>
          ) : null}
        </div>
      </div>
    )
  }
  if (loading) {
    return (
      <Container
        className="ln-u-text-align-center ln-u-padding-top*4"
        size="xs"
      >
        <ProgressIndicator
          className="ln-u-justify-content-center"
          loading
          preventFocus
          style={{ position: 'sticky', zIndex: 2 }}
        >
          <ProgressSpinner size="small" />
        </ProgressIndicator>
      </Container>
    )
  }

  return (
    <Container
      id="r-deMerchandiseForm"
      className="ln-u-padding-ends*3"
      size="xs"
    >
      <div className="ln-u-padding*2">
        <Display1>De-merchandise fixture</Display1>
      </div>
      <Card>
        <div className="ln-u-padding-bottom">
          <div className="ln-u-padding-bottom*2">
            The listed fixture(s) is <b>over temperature</b> and needs to be
            de-merchandised to safeguard stock and customers.
          </div>
          <div className="ln-u-padding-bottom*2">
            A work order has been created, so that an engineer can investigate
            (remotely or in store).
          </div>
          <div className="ln-u-padding-bottom*2">
            <b>If the fixture has been previously checked, you need to:</b>
            <ul className="ln-u-flush-bottom">
              <li>
                De-merchandise the fixture to save stock and protect our
                customers.
              </li>
            </ul>
          </div>
          <div className="ln-u-padding-bottom*2">
            <b>
              If no check has been done after 30 mins of this message, you need
              to:
            </b>
            <ul className="ln-u-flush-bottom">
              <li>
                De-merchandise the fixture to save stock and protect our
                customers.
              </li>
              <li>
                Temperature check products whilst they’re within the
                refrigerator, placing the probe in between products. Depending
                on the temperature of the product you will need to follow the No
                Food Waste to Landfill process or de-merchandise.
              </li>
            </ul>
          </div>

          {buttonIsDisabled || alarm.isParentAlarmed ? null : showImageUpload()}

          <FormGroup
            name="r-submit-button"
            error={errorMessage}
            className="ln-u-flush-bottom"
          >
            <FilledButton
              id="r-submitButton"
              fullWidth
              disabled={
                !alarm.isParentAlarmed &&
                  (uploadedImages && (uploadedImages.length > 3 || uploadedImages.length === 0))
                  ? true
                  : buttonIsDisabled
              }
              onClick={() => sendResponse()}
            >
              Confirm fridge is empty
            </FilledButton>
          </FormGroup>
          {showCheckCompletedMessage ? checkCompletedMessage() : null}
          <WorkOrderLink workOrderNumber={alarm.woNumber} />
        </div>
      </Card>
    </Container>
  )
}

export default DeMerchandiseForm
