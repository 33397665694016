import { Body2 } from '@jsluna/typography'

const Description = ({ text }: any) => {
  return (
    <div>
      <Body2
        id="r-card-description"
        className="r-card-description ln-u-font-weight-bold ln-u-color-dark"
      >
        {text}
      </Body2>
    </div>
  )
}

export default Description
