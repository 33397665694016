import { useState } from 'react'
import { Container } from '@jsluna/react'
import { Modal, ModalHeading } from '@jsluna/modal'
import { FilteringPanel } from '../components/Filtering/FilteringPanel'
import { FilterPills } from '../components/Filtering/FilterPills'
import alarmHistoryFilters from '../utils/alarmHistoryFilters.json'
import useFilters from '../hooks/useFilters'
import AlarmsHistory from '../components/AlarmsLists/AlarmsHistory'

const AlarmsHistoryPage = () => {
  const [openFilterPanel, setOpenFilterPanel] = useState(false)
  const [filters, handleFilters] = useFilters()
  const handleModal = (value: boolean) => {
    setOpenFilterPanel(value)
  }

  if (openFilterPanel) {
    return (
      <Container id="r-filter-panel-mobile">
        <Modal
          fullScreen
          open={openFilterPanel}
          handleClose={() => handleModal(false)}
        >
          <ModalHeading element="h2">Filters</ModalHeading>
          <FilteringPanel
            filters={filters}
            passOpenFilterPanel={handleModal}
            filtersList={alarmHistoryFilters}
            handleFilters={handleFilters}
          />
        </Modal>
      </Container>
    )
  }

  return (
    <Container
      className="r-alarm-history-container ln-u-flush-left"
      id="r-alarm-history-page"
    >
      <div className="dm__filter-panel-wrapper ln-u-grid">
        <Container className="r-filter-panel" id="r-filter-panel">
          <FilteringPanel filters={filters} filtersList={alarmHistoryFilters} handleFilters={handleFilters} />
        </Container>
      </div>

      <div className="r-alarm-history-dashboard ln-u-1/1" id="dashboard-card">
        <Container className="r-pageName r-filter-mobile" size="xs">
          <FilterPills
            filters={filters}
            handleFilters={handleFilters}
            handleModal={handleModal}
          />
        </Container>
        <AlarmsHistory filters={filters} handleFilters={handleFilters} />
      </div>
    </Container>
  )
};

export default AlarmsHistoryPage;