import { ApiClient } from './ApiClient'
import webApi from './webApi'
import { IAlarmsPaginated, IAlarmGuidance, IPostAlarmResponse, IAlarms } from '../types'

const PostAlarmResponse = async (
  apiClient: ApiClient,
  storeCode: string,
  username: string,
  alarm: { assetNumber: any; id: any },
  responseType: any,
  responseComment: string | null = null,
  isDemerchandiseRequest = false
): Promise<IPostAlarmResponse> => {
  const data = {
    siteName: storeCode,
    user: username,
    assetNumber: alarm.assetNumber,
    alarmId: alarm.id,
    responseType,
    comment: responseComment,
    isDemerchandiseRequest
  }

  const response = await webApi.post(apiClient, {
    url: `/api/alarms/response`,
    params: [],
    body: JSON.stringify(data)
  })
  
  const responseData = await response.json() as IPostAlarmResponse

  if (response.status === 200)
    return {
      status: responseData.status,
      message: responseData.message
    }
  else {
    return {
      status: false,
      message: responseData.message
    }
  }
}

const GetAlarmsImageGuidance = async (
  apiClient: ApiClient,
  isDemerchandise: boolean,
  productType: string,
  assetDescription: string,
  assetDepartment: string
): Promise<IAlarmGuidance[]> => {
  const response = await webApi.get<IAlarmGuidance[]>(apiClient, {
    url: `/api/alarms/guidance`,
    params: {
      isDemerchandise: isDemerchandise,
      productType: productType,
      assetDescription: assetDescription,
      assetDepartment: assetDepartment
    }
  })
  return response
}

const GetAlarmsHistory = async (
  apiClient: ApiClient,
  storeCode: string,
  filters: any,
  pageNumber: number
): Promise<IAlarmsPaginated> => {
  const filtersList: any[] = []

  if (filters) {
    filters.forEach((filterDetails: any) =>
      filtersList.push(filterDetails.filterValue.toUpperCase())
    )
  }

  const response = await webApi.get<IAlarmsPaginated>(apiClient, {
    url: 'api/alarms/history',
    params: {
      siteName: storeCode,
      filters: filtersList.join(',').toString(),
      pageNumber: pageNumber
    }
  })
  return response
}

const GetAlarmsPaginated = async (
  apiClient: ApiClient,
  storeCode: string,
  filters: any,
  pageNumber: number,
  alarmType: string
): Promise<IAlarmsPaginated> => {
  const filtersList: any[] = []

  if (filters) {
    filters.forEach((filterDetails: any) =>
      filtersList.push(filterDetails.filterValue)
    )
  }

  const response = await webApi.get<IAlarmsPaginated>(apiClient, {
    url: `api/alarms`,
    params: {
      siteName: storeCode,
      filters: filtersList.join(',').toString(),
      alarmType: alarmType,
      pageNumber: pageNumber
    }
  })
  return response
}

const GetAlarms = async (
  apiClient: ApiClient,
  externalId: string,
  storeCode: string
): Promise<IAlarms> => {

  const response = await webApi.get<IAlarms>(apiClient, {
    url: `api/alarms/${externalId}`,
    params: {
      externalId: externalId,
      siteName: storeCode
    }
  })
  return response
}

const alarmsApi = {
  GetAlarmsHistory,
  GetAlarmsImageGuidance,
  PostAlarmResponse,
  GetAlarmsPaginated,
  GetAlarms
}

export default alarmsApi
