import { Body1 } from '@jsluna/typography'

interface ProductTypeParams {
  details?: any,
  cardType?: string,
}

const ProductType = ({ details, cardType }: ProductTypeParams) => {
  if (cardType === 'assetDetailsPage')
    return (
      <Body1
        className="ln-u-font-weight-bold ln-u-color-dark"
        id="r-asset-productType"
      >
        {details.merchandising == null ||
        details.merchandising.productType === null
          ? details.assetTypeDescription
          : details.merchandising?.productType}
      </Body1>
    )

  return (
    <Body1
      className="ln-u-font-weight-bold ln-u-color-dark"
      id="r-asset-productType"
    >
      {details?.productType}
    </Body1>
  )
}

export default ProductType
