import { PropTypes } from 'prop-types'
import { Container } from '@jsluna/react'
import { Card } from '@jsluna/card'
import { Body1, Body2 } from '@jsluna/typography'
import { ErrorCircle } from '@jsluna/icons'
import { FilledButton } from '@jsluna/button'
import { SainsburysHelpDesk } from '../../../../utils/helpNumbers'
import getRelativeDate from '../../../../utils/relativeDate'
import { useNavigate } from 'react-router'

function FailingMessage({ productTypeChangeData }) {
  const history = useNavigate()
  const alternateReasonMessage = () => (
    <>
      You will need to make the request again, until the change request is
      successful. If this problem continues please contact the&nbsp;
      <b>Sainsbury&apos;s Tech Service Desk</b>&nbsp;on{' '}
      <b>{SainsburysHelpDesk}</b>
    </>
  )

  return (
    <Container className="ln-u-padding-top" size="xs">
      <Card className="r-request-failing-message ln-u-margin-bottom*2">
        <div className="ln-u-text-align-center ln-u-padding-ends*2">
          <Body2
            id="r-request-failing-header"
            className="ln-u-font-weight-bold"
          >
            <ErrorCircle
              id="r-errorCircle"
              className="r-error-icon ln-u-color-red-80 ln-u-display-3"
            />
            <br />
            Request failed
          </Body2>
        </div>
        <div>
          <Body1
            id="r-previous-product-type"
            className="r-previous-product-type"
          >
            <b>from</b> {productTypeChangeData?.currentProductType}
          </Body1>
        </div>
        <div>
          <Body1 id="r-new-product-type" className="r-new-product-type">
            <b>to</b> {productTypeChangeData?.newProductType}
          </Body1>
        </div>
        <div>
          <Body1 id="r-timestamp" className="r-timestamp ln-u-color-grey">
            {productTypeChangeData?.timestamp
              ? getRelativeDate(productTypeChangeData?.timestamp)
              : null}
          </Body1>
        </div>
        <div className="r-message ln-u-padding-ends*2">
          <Body1 id="r-request-failing-text">
            {productTypeChangeData?.reason != null
              ? productTypeChangeData?.reason
              : alternateReasonMessage()}
          </Body1>
        </div>
        <FilledButton id="r-return-button" fullWidth onClick={() => history(-1)}> 
          Back to fixture list
        </FilledButton>
      </Card>
    </Container>
  )
}

FailingMessage.propTypes = {
  productTypeChangeData: PropTypes.shape({
    currentProductType: PropTypes.string,
    newProductType: PropTypes.string,
    timestamp: PropTypes.string,
    reason: PropTypes.string,
  }),
}

FailingMessage.defaultProps = {
  productTypeChangeData: null,
}

export default FailingMessage
