import {
  FixtureCommunicationAlarmName,
  MultipleFixtureCommunicationAlarmName,
  PackAlarmName,
} from './alarmConstants'
import countDifferenceInDays from './countDifferenceInDays'

const IsRecentlyClosed = closedTimestamp => {
  return closedTimestamp !== '' && countDifferenceInDays(closedTimestamp) <= 1
}

const getClosedEventTimestamp = (event, closedTimestamp) => {
  return event.eventType === 'CLOSED' &&
    (closedTimestamp === '' ||
      (closedTimestamp !== '' && closedTimestamp < event.timestamp))
    ? event.timestamp
    : closedTimestamp
}

const checkAlarmParameters = assetEventsHistoryResponse => {
  let closedPackAlarmTimestamp = ''
  let isPackAlarmClosedRecently = false
  let closedPackAlarmId = null

  let closedFixtureCommAlarmTimestamp = ''
  let isFixtureCommunicationAlarmClosedRecently = false

  let closedMultipleFixtureCommAlarmTimestamp = ''
  let isMultipleFixtureCommunicationAlarmClosedRecently = false
  let closedMultipleFixtureCommAlarmId = null

  Array.from(assetEventsHistoryResponse).forEach(historyEvent => {
    if (historyEvent.alarmName === PackAlarmName) {
      closedPackAlarmTimestamp = getClosedEventTimestamp(
        historyEvent,
        closedPackAlarmTimestamp,
      )

      isPackAlarmClosedRecently =
        closedPackAlarmTimestamp !== '' &&
        IsRecentlyClosed(closedPackAlarmTimestamp)

      closedPackAlarmId =
        isPackAlarmClosedRecently &&
        historyEvent.event !== 'PARENT_ALARM' &&
        closedPackAlarmTimestamp === historyEvent.timestamp
          ? historyEvent.alarmId
          : closedPackAlarmId
    }
    if (historyEvent.alarmName === MultipleFixtureCommunicationAlarmName) {
      closedMultipleFixtureCommAlarmTimestamp = getClosedEventTimestamp(
        historyEvent,
        closedMultipleFixtureCommAlarmTimestamp,
      )

      isMultipleFixtureCommunicationAlarmClosedRecently =
        closedMultipleFixtureCommAlarmTimestamp !== '' &&
        IsRecentlyClosed(closedMultipleFixtureCommAlarmTimestamp)

      closedMultipleFixtureCommAlarmId =
        isMultipleFixtureCommunicationAlarmClosedRecently &&
        historyEvent.event !== 'PARENT_ALARM' &&
        closedMultipleFixtureCommAlarmTimestamp === historyEvent.timestamp
          ? historyEvent.alarmId
          : closedMultipleFixtureCommAlarmId
    }

    if (historyEvent.alarmName === FixtureCommunicationAlarmName) {
      closedFixtureCommAlarmTimestamp = getClosedEventTimestamp(
        historyEvent,
        closedFixtureCommAlarmTimestamp,
      )

      isFixtureCommunicationAlarmClosedRecently =
        closedFixtureCommAlarmTimestamp !== '' &&
        historyEvent.event !== 'PARENT_ALARM' &&
        IsRecentlyClosed(closedFixtureCommAlarmTimestamp)
    }
  })

  return {
    isFixtureCommunicationAlarmClosedRecently,
    isMultipleFixtureCommunicationAlarmClosedRecently,
    isPackAlarmClosedRecently,
    closedMultipleFixtureCommAlarmId,
    closedPackAlarmId,
  }
}

export default checkAlarmParameters
