import { Container } from '@jsluna/react'
import { Body2, Heading4 } from '@jsluna/typography'
import { FilledButton } from '@jsluna/button'
import thumbIcon from '../../../icons/positiveIcon.png'

const NoFilteredAlarmsMessage = ({ filters, handleFilters }: any) => {
  const clearFilters = () => {
    handleFilters(filters, "reset")
  }

  return (
    <div>
      <Container className="ln-u-soft">
        <Heading4 className="ln-u-soft-top ln-u-text-align-center">
          <img src={thumbIcon} alt="positiveIcon" className="ln-u-1/5" />
          <div>There&apos;s nothing to see here</div>
        </Heading4>
        <Body2>
          <div className="ln-u-padding-bottom*4">
            There&apos;s no alarms which match your filter.
          </div>
          <div>Please refilter to find the alarm your looking for.</div>
        </Body2>
        <FilledButton
          id="r-clearFilters"
          className="ln-u-margin-top*4"
          fullWidth
          onClick={() => clearFilters()}
        >
          Clear filters
        </FilledButton>
      </Container>
    </div>
  )
}

export default NoFilteredAlarmsMessage
