import { NavLink } from 'react-router-dom'
import { Tutorial, OpenButton } from '@jsainsburyplc/retail-stateful-tutorial'
import { useApiClient } from '../../context/AppContext'

function WhatsNew() {
  const apiClient = useApiClient()
  const tutorialFetch = (url: any, options: any) => {
    return apiClient.tutorialFetchAuth(url, options)
  }
  const environment =
    window.location.href.indexOf('https://refrigeration.sainsburys.co.uk') > -1
      ? 'prod'
      : 'dev'

  return (
    <div>
      <Tutorial
        project="refrigeration"
        version={1}
        fetch={tutorialFetch}
        environment={environment}
      >
        <OpenButton>
          {(showTutorial: any) => (
            <NavLink to="#" name="Tutorial" onClick={() => showTutorial()}>
              What&apos;s New
            </NavLink>
          )}
        </OpenButton>
      </Tutorial>
    </div>
  )
}

export default WhatsNew
