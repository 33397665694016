import { useEffect, useState } from 'react'
import assetsApi from '../../../api/assetsApi'
import checkAlarmParameters from '../../../utils/checkAlarmParameters'
import {
  MultipleFixtureCommunicationAlarmName,
  PackAlarmName,
} from '../../../utils/alarmConstants'
import ChildAssetsDetails from './ChildAssetsDetails'
import { IChildAssets } from '../../../types'
import { useApiClient } from '../../../context/AppContext'

interface ChildAssetsParams {
  data: any
}

const ChildAssets = ({data}: ChildAssetsParams) => {
  const [childAssets, setChildAssets] = useState<Array<any> | null>([])
  const [alarmID, setAlarmID] = useState<string | null>(null)
  const [alarmTypeDescription, setAlarmTypeDescription] = useState<string | null>(null)
  const apiClient = useApiClient()

  const setAlarmIdAndDescription = (alarmName: string | null, alarmId: string) => {
    if (alarmName === PackAlarmName) {
      setAlarmID(alarmId)
      setAlarmTypeDescription(
        'For the following fixtures associated to this alarm:',
      )
    }
    if (alarmName === MultipleFixtureCommunicationAlarmName) {
      setAlarmID(alarmId)
      setAlarmTypeDescription(
        'The following cabinets are associated to the Multiple fixture alarm:',
      )
    }
  }

  const checkClosedAlarmDetails = () => {
    const recentlyClosedAlarmDetails = checkAlarmParameters(
      data.assetEventsHistoryResponse,
    )
    if (recentlyClosedAlarmDetails.closedMultipleFixtureCommAlarmId !== null) {
      setAlarmIdAndDescription(
        MultipleFixtureCommunicationAlarmName,
        recentlyClosedAlarmDetails.closedMultipleFixtureCommAlarmId,
      )
    }

    if (recentlyClosedAlarmDetails.closedPackAlarmId !== null) {
      setAlarmIdAndDescription(
        PackAlarmName,
        recentlyClosedAlarmDetails.closedPackAlarmId,
      )
    }
  }

  useEffect( () => {
    const getChildAssets = async () => {
      let childAssetDetails: Array<any> = []
  
      data?.alarms?.forEach( (alarm: any) => {
        setAlarmIdAndDescription(alarm.alarmName, alarm.id)
  
        if (
          alarmID !== null &&
          alarm.childAssets !== null &&
          alarm.childAssets?.length > 0
        )
          childAssetDetails = alarm.childAssets
      })
  
      if (alarmID === null) {
        checkClosedAlarmDetails()
      }
  
      if (alarmID !== null && childAssets?.length === 0) {
        const childAssetResponse: IChildAssets[] = await assetsApi
          .GetChildAssets(apiClient, data.assetNumber, alarmID)
          .then(value => {
            return value
          })
        if (childAssetResponse != null && childAssetResponse.length !== 0) {
          childAssetDetails = childAssetResponse
        }
      }
      setChildAssets(childAssetDetails)
    }
    getChildAssets()
  }, [alarmID])

  if (alarmID !== null && childAssets && childAssets?.length > 0)
    return (
      <ChildAssetsDetails
        childAssets={childAssets}
        siteName={data.siteName}
        alarmTypeDescription={alarmTypeDescription}
      />
    )
  return null
}

export default ChildAssets
