import { useEffect, useRef, useState } from 'react'
import { OutlinedButton } from '@jsluna/button'
import { FormGroup } from '@jsluna/form'
import { ProgressIndicator, ProgressBar } from '@jsluna/progress'
import ImagePreview from './ImagePreview'
import { IImages } from '../../types'

interface ImageUploadParams {
  uploadedImages: IImages[] | undefined,
  buttonDisabled: boolean,
  sendImages: (images:any) => void,
  removeImage: (imageName:string) => void,
  errorMessage?: string,
  lastResponseTime?: string,
}

const ImageUpload = ({uploadedImages, buttonDisabled, sendImages, removeImage, errorMessage = '', lastResponseTime = ''}: ImageUploadParams) => {
  const maxImageNumber = 3
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState(uploadedImages)
  const [imageAddingFailed, setImageAddingFailed] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [message, setMessage] = useState('')
  const [buttonIsDisabled, setButtonIsDisabled] = useState(buttonDisabled)
  const [checkNewImages, setCheckNewImages] = useState(false)
  const fileInputRef = useRef<any>(null)
  const [showImagePreview, setShowImagePreview] = useState(true)

  const checkPreviouslyUploadedImages = async () => {
    if (uploadedImages && uploadedImages.length >= maxImageNumber && buttonIsDisabled) {
      setMessage(
        `You have already uploaded the maximum number of images (${maxImageNumber} images)`,
      )
    } else if (uploadedImages && uploadedImages.length === 0) {
      setMessage('')
    } else if (
      uploadedImages &&
      uploadedImages.length > 0 &&
      uploadedImages.length < maxImageNumber &&
      !buttonIsDisabled
    ) {
      setMessage(
        `You have already uploaded ${uploadedImages.length} image(s). 
        You can upload ${maxImageNumber - uploadedImages.length} more image(s)`,
      )
    }

    if (
      lastResponseTime !== '' &&
      uploadedImages &&
      uploadedImages.length >= 3 &&
      uploadedImages.every(image => image.timestamp <= lastResponseTime)
    ) {
      setShowImagePreview(false)
    } else setShowImagePreview(true)
  }

  const updateImageErrorMessage = async () => {
    switch (true) {
      case buttonDisabled: {
        setButtonIsDisabled(true)
        break
      }
      case imageAddingFailed: {
        setButtonIsDisabled(false)
        setError(`Can not upload more than ${maxImageNumber} images`)
        break
      }
      case images && images.length > maxImageNumber: {
        setButtonIsDisabled(true)
        setError(`Can not add more than ${maxImageNumber} images`)
        break
      }
      case images && images.length === maxImageNumber: {
        setButtonIsDisabled(true)
        setError('')
        break
      }
      case errorMessage !== ' ': {
        setButtonIsDisabled(false)
        setError(errorMessage)
        break
      }
      case images && images.length === 0 && !loading: {
        setButtonIsDisabled(false)
        setError('Please add at least 1 image')
        break
      }
      default: {
        setButtonIsDisabled(false)
        setError('')
      }
    }
  }

  useEffect(() => {
    checkPreviouslyUploadedImages()
    updateImageErrorMessage()
    setImages(uploadedImages)
  })

  const handleUpload = async (e:any) => {
    e.preventDefault()

    const uploadedFiles = Array.from(e.target.files)

    setLoading(true)
    setImageAddingFailed(false)
    if (uploadedFiles.length <= maxImageNumber) {
      sendImages(uploadedFiles)
      setCheckNewImages(true)
    } else {
      setImageAddingFailed(true)
      setCheckNewImages(false)
    }

    setLoading(false)
    fileInputRef.current.value = null
  }

  const handleRemoveImage = async (image:any) => {
    removeImage(image.fileName)
  }

  return (
    <>
      <div>
        <FormGroup name="a-files-upload-button" error={error}>
          <OutlinedButton
            id="r-imageUploadButton"
            element="label"
            fullWidth
            label="Add image"
            disabled={buttonIsDisabled}
          >
            <input
            title='imageUpload'
              type="file"
              accept="image/*"
              id="a-filesUploadButton"
              name="Same text"
              multiple
              onChange={handleUpload}
              className="fileInput"
              ref={fileInputRef}
              disabled={buttonIsDisabled}
            />
            Add image
          </OutlinedButton>
        </FormGroup>
      </div>
      <div className="ln-u-push-bottom-lg">{message}</div>
      {checkNewImages || showImagePreview ? (
        <ImagePreview removeImage={handleRemoveImage} images={images} />
      ) : null}

      <div id="a-imageUploadMessage">
        {loading && (
          <ProgressIndicator loading preventFocus>
            <ProgressBar
              className="ln-u-push-bottom-lg"
              title="Regular sized progress indicator demo"
            />
          </ProgressIndicator>
        )}
      </div>
    </>
  )
}

export default ImageUpload
