import { useState } from 'react'
import { Container } from '@jsluna/react'
import { Modal, ModalHeading } from '@jsluna/modal'
import FilteringPanel from '../components/Filtering/FilteringPanel/FilteringPanel'
import alarmOverviewFilters from '../utils/alarmOverviewFilters.json'
import { FilterPills } from '../components/Filtering/FilterPills'
import FixtureAlarms from '../components/AlarmsLists/FixtureAlarms'
import StoreAlarms from '../components/AlarmsLists/StoreAlarms'
import useFilters from '../hooks/useFilters'

const AlarmsPage = () => {
 
  const [openFilterPanel, setOpenFilterPanel] = useState(false)
  const [filters, handleFilters] = useFilters()

  const handleModal = (value: boolean) => {
    setOpenFilterPanel(value)
  }

  if (openFilterPanel) {
    return (
      <Container id="r-filter-panel-mobile">
        <Modal
          fullScreen
          open={openFilterPanel}
          handleClose={() => handleModal(false)}
        >
          <ModalHeading element="h2">Filters</ModalHeading>
            <FilteringPanel
              filters={filters}
              passOpenFilterPanel={handleModal}
              filtersList={alarmOverviewFilters}
              handleFilters={handleFilters}
            />
        </Modal>
      </Container>
    )
  }

  return (
    <Container
      className="r-alarm-overview-container ln-u-flush-left"
      id="r-alarm-overview-page"
    >
      <div className="dm__filter-panel-wrapper ln-u-grid">
        <Container className="r-filter-panel" id="r-filter-panel">
          <FilteringPanel filters={filters} filtersList={alarmOverviewFilters} handleFilters={handleFilters} />
        </Container>
      </div>

      <div className="r-alarm-overview-dashboard ln-u-1/1" id="dashboard-card">
        <Container className="r-pageName r-filter-mobile" size="xs">
          <FilterPills
            filters={filters}
            handleFilters={handleFilters}
            handleModal={handleModal}
          />
        </Container>
        <StoreAlarms filters={filters}/>
        <FixtureAlarms filters={filters}/>
      </div>
    </Container>
  )
};

export default AlarmsPage