import { Body1 } from '@jsluna/typography'

interface DepartmentParams {
  department: string | undefined,
}

const Department = ({department}: DepartmentParams) => {
  return (
    <div>
      <Body1 id="r-asset-department" className="ln-u-color-dark">
        {department && department?.charAt(0) + department?.slice(1).toLowerCase()}
      </Body1>
    </div>
  )
}

export default Department
