import { PropTypes } from 'prop-types'
import { Card } from '@jsluna/card'
import { Body2 } from '@jsluna/typography'
import { GridItem, GridWrapper } from '@jsluna/grid'
import { ArrowRight } from '@jsluna/icons'
import Timestamp from '../ExtraElements/Timestamp'

function WoMessageCard({ event, showFmAssistLink }) {
  const woNumber = event.woNumber?.replace('#', '').replace(/\s+/g, '')
  return (
    <Card>
      <GridWrapper verticalAlign="middle">
        <GridItem size="9/10">
          <div id="r-event-header" className="r-event-header">
            <Body2 className="ln-u-font-weight-bold">{event.eventName}</Body2>
          </div>
          <div className="ln-u-soft-bottom-sm">
            <Body2 className="a-wo-number ln-u-text-decoration-underline">
              {woNumber}
            </Body2>
          </div>
          <Timestamp timestamp={event.timestamp} />
        </GridItem>
        {showFmAssistLink ? (
          <GridItem size="1/10">
            <ArrowRight size="large" />
          </GridItem>
        ) : null}
      </GridWrapper>
    </Card>
  )
}
WoMessageCard.propTypes = {
  event: PropTypes.shape({
    alarmId: PropTypes.string,
    eventName: PropTypes.string,
    timestamp: PropTypes.string,
    woNumber: PropTypes.string,
  }).isRequired,
  showFmAssistLink: PropTypes.bool,
}
WoMessageCard.defaultProps = {
  showFmAssistLink: false,
}
export default WoMessageCard
