import { useState } from 'react'
import { Card } from '@jsluna/card'
import { Container } from '@jsluna/react'
import { Display1 } from '@jsluna/typography'
import { FilledButton } from '@jsluna/button'
import {
  FixtureCommunicationAlarmName,
  LeakAlarmName,
  PackAlarmName,
  TemperatureAlarmName,
  MultipleFixtureCommunicationAlarmName,
  WaterLeakAlarmName
} from '../../../../utils/alarmConstants'

import OverTemperatureAlarm from '../OverTemperatureAlarm'

import DeMerchandiseForm from '../DeMerchandiseForm'
import PackAlarm from '../PackAlarm'
import FixtureCommunicationAlarm from '../FixtureCommunicationAlarm/FixtureCommunicationAlarm'

import LeakAlarm from '../LeakAlarm'
import MultipleFixtureCommunicationAlarm from '../MultipleFixtureCommunicationAlarm'
import WaterLeakAlarm from '../WaterLeakAlarm'
import { useCurrentStore, useUser } from '../../../../hooks'
import { IAlarms } from '../../../../types/AlarmsPaginated/IAlarmsPaginated'

interface IndexFormParams {
  alarm: IAlarms
  historyEvents?: Array<any> | null
}

const IndexForm = ({ alarm, historyEvents = null }: IndexFormParams) => {
  const storeCode = useCurrentStore().storeCode
  const { username } = useUser()
  const [goToNextPage, setGoToNextPage] = useState(false)
  const [showDeMerchandise, setShowDeMerchandise] = useState(
    alarm.alarmName === TemperatureAlarmName &&
    alarm.woNumber !== null &&
    alarm.woNumber !== ''
  )
  const startCheck = () => {
    setGoToNextPage(true)
  }

  if (alarm.alarmName === PackAlarmName && alarm.status !== 'Closed') {
    return (
      <PackAlarm
        alarm={alarm}
        historyEvents={historyEvents}
        storeCode={storeCode}
        userName={username}
      />
    )
  }
  if (
    alarm.alarmName === FixtureCommunicationAlarmName &&
    alarm.status !== 'Closed'
  ) {
    return (
      <FixtureCommunicationAlarm
        alarm={alarm}
        historyEvents={historyEvents}
        storeCode={storeCode}
        userName={username}
      />
    )
  }
  if (alarm.alarmName === LeakAlarmName && alarm.status !== 'Closed') {
    return (
      <LeakAlarm
        alarm={alarm}
        historyEvents={historyEvents}
        storeCode={storeCode}
        userName={username}
      />
    )
  }
  if (alarm.alarmName === WaterLeakAlarmName && alarm.status !== 'Closed') {
    return (
      <WaterLeakAlarm
        alarm={alarm}
        historyEvents={historyEvents}
        storeCode={storeCode}
        userName={username}
      />
    )
  }
  if (
    alarm.alarmName === MultipleFixtureCommunicationAlarmName &&
    alarm.status !== 'Closed'
  ) {
    return (
      <MultipleFixtureCommunicationAlarm
        alarm={alarm}
        historyEvents={historyEvents}
        storeCode={storeCode}
        userName={username}
      />
    )
  }

  if (alarm.status === 'Closed' || alarm.alarmName !== TemperatureAlarmName) {
    return null
  }

  if (showDeMerchandise) {
    return (
      <DeMerchandiseForm
        alarm={alarm}
        storeCode={storeCode}
        userName={username}
        showDeMerchandise={showDeMerchandise}
      />
    )
  }

  if (alarm.lastResponse !== null || goToNextPage) {
    return (
      <OverTemperatureAlarm
        alarm={alarm}
        storeCode={storeCode}
        userName={username}
        showDeMerchandise={showDeMerchandise}
        setShowDeMerchandise={setShowDeMerchandise}
      />
    )
  }

  return (
    <Container
      id="r-refrigerationCheckIndexForm"
      className="ln-u-padding-ends*3"
      size="xs"
    >
      <div className="ln-u-padding*2">
        <Display1>Locate and check the fridge</Display1>
      </div>
      <Card id="r-refrigerationCheckIndexFormCard">
        You’ll need to check the fridge for:
        <ul className="ln-u-flush-bottom">
          <ul>
            <li>open doors</li>
            <li>blocked air vents</li>
            <li>overstocking</li>
          </ul>
        </ul>
        <FilledButton
          id="r-startAlarmCheckButton"
          fullWidth
          onClick={() => startCheck()}
        >
          Start check
        </FilledButton>
      </Card>
    </Container>
  )
}

export default IndexForm
