import { Button } from '@jsluna/button'
import { Cancel } from '@jsluna/icons'
import { Caption } from '@jsluna/typography'

interface FilteringPanelParams {
  filters: any,
  handleFilters: any,
  handleModal: (value: boolean) => void
}

const FilterPills = ({filters, handleFilters, handleModal}: FilteringPanelParams) => {

  const deleteFilter = (filter:any) => {
    handleFilters(filters, "remove", filter)
  }

  return (
    <div className="ln-u-padding-ends">
      {filters.map((filter: any, index:any) => (
        <Button
          key={index}
          className="r-filter-pill"
          id={`r-filter-pill-${filter}`}
          variant="filled"
          onClick={() => deleteFilter(filter)}
        >
          <Caption>
            {filter.filterName} <Cancel />
          </Caption>
        </Button>
      ))}
      <Button
        className="r-more-filters-button"
        id="r-more-filters-button"
        onClick={() => handleModal(true)}
      >
        All filters
      </Button>
    </div>
  )
}

export default FilterPills
