import { Card } from '@jsluna/card'
import { Body1, Body2 } from '@jsluna/typography'
import { Account } from '@jsluna/icons'
import { ProgressSpinner } from '@jsluna/progress'
import Timestamp from '../ExtraElements/Timestamp'
import ImageThumbnail from '../ExtraElements/ImageThumbnail'
import useImages from '../../../../../hooks/useImages'
import { useEffect } from 'react'
import { IImages } from '../../../../../types'

interface AlarmResponseMessageParams {
  assetNumber: string
  alarmEvent: {
    eventName: string
    answerType: string
    timestamp: string
    user: string
    alarmId: string
    eventType: string
  }
}

const AlarmResponseMessage = ({
  assetNumber,
  alarmEvent
}: AlarmResponseMessageParams) => {
  const [imageResource, imageLoader] = useImages()
  let imagesData: IImages[] | null | undefined = null

  useEffect(() => {
    if (assetNumber !== null)
      imageLoader.current(
        assetNumber,
        alarmEvent.alarmId,
        alarmEvent.eventType,
        alarmEvent.timestamp
      )
  }, [
    alarmEvent.alarmId,
    alarmEvent.eventType,
    alarmEvent.timestamp,
    assetNumber,
    imageLoader
  ])

  if (imageResource.hasLoaded && !imageResource.hasError) {
    imagesData = imageResource.data
  }

  const answerDescription = (answerType: string) => {
    switch (answerType) {
      case 'create_wo': {
        return (
          <div className="r-event-message-body ln-u-soft-bottom-sm">
            <Body1>
              No visible issues were reported and an engineer was requested to
              investigate further
            </Body1>
          </div>
        )
      }
      case 'dont_create_wo': {
        return (
          <div className="r-event-message-body ln-u-soft-bottom-sm">
            <Body1>
              Colleague has found and corrected something that might have caused
              the alarm
            </Body1>
          </div>
        )
      }
      default:
        return null
    }
  }

  const showImages = () => {
    if (!imageResource.hasLoaded && !imageResource.hasError)
      return (
        <div className="r-event-images ln-u-soft-bottom-sm">
          <ProgressSpinner size="small" />
        </div>
      )

    if (imageResource.hasError && (!imagesData || imagesData.length === 0))
      return (
        <div className="r-event-images ln-u-soft-bottom-sm">
          `[Unable to get image(s)]`
        </div>
      )

    if (imagesData && imagesData.length > 0) {
      return (
        <div className="r-event-images ln-u-soft-bottom-sm">
          {imagesData.map((imageDetails: any) => (
            <ImageThumbnail key='image' link={imageDetails.filePath} />
          ))}
        </div>
      )
    }

    return null
  }

  return (
    <div className="r-event-alarm-check ln-u-padding-bottom">
      <Card>
        <div id="r-event-header" className="r-event-header ln-u-soft-bottom-sm">
          <Body2 className="ln-u-font-weight-bold">
            {alarmEvent.eventName}
          </Body2>
        </div>
        {alarmEvent.user.includes('Verisae') ||
        (alarmEvent.eventType === 'RESPONSE' &&
          (alarmEvent.answerType === 'confirm_action' ||
            alarmEvent.answerType === 'wo_created')) ? null : (
          <div>
            {showImages()}
            {answerDescription(alarmEvent.answerType)}
          </div>
        )}
        <Timestamp timestamp={alarmEvent.timestamp} />
        <div id="r-event-user" className="r-event-user-name">
          <Account /> {alarmEvent.user}
        </div>
      </Card>
    </div>
  )
}

export default AlarmResponseMessage
