import { GridItem } from '@jsluna/grid'
import { Cancel } from '@jsluna/icons'
import { IImages } from '../../types'

interface ImagePreviewParams {
  images: IImages[] | undefined
  removeImage:(image:any)=> any
}
const ImagePreview = ({ images, removeImage }: ImagePreviewParams) => {
  if (!images || images.length <= 0) {
    return null
  }

  return Object.entries(images).map(([index, image]) => (
    <GridItem size={{ xs: '1/2' }} key={index}>
      <div id="a-imageContainer" className="a-image-container">
        {!image.isUploadedToVerisae ? (
          <button
          title='removeImage'
            type="button"
            id="a-removeImageButton"
            className="a-remove-image-button ln-u-margin-left icon-top-left"
            onClick={() => removeImage(image)}
          >
            <Cancel size="Large" fixed />
          </button>
        ) : null}
        <img
          src={image.filePath}
          className="a-uploaded-image"
          alt="issueImage"
          id="a-imageThumbnail"
        />
      </div>
    </GridItem>
  ))
}

export default ImagePreview
