import {
  AuthenticationResult,
  IPublicClientApplication
} from '@azure/msal-browser'
import { IErrorResponse } from '../types/IErrorResponse'

export class ApiClient {
  private instance: IPublicClientApplication

  private apiScope: string
  private tutorialServiceScope: string
  private baseUrl: string

  constructor(
    instance: IPublicClientApplication,
    apiScope: string,
    tutorialServiceScope: string,
    baseUrl: string
  ) {
    this.instance = instance
    this.apiScope = apiScope
    this.tutorialServiceScope = tutorialServiceScope
    this.baseUrl = baseUrl
  }

  public async getAccessToken(
    isTutorialService = false
  ): Promise<string | null> {
    const accounts = this.instance.getAllAccounts()

    if (accounts.length === 0) {
      return null
    }

    const account = accounts[0]

    try {
      const authResult: AuthenticationResult =
        await this.instance.acquireTokenSilent({
          account,
          scopes: [
            isTutorialService ? this.tutorialServiceScope : this.apiScope
          ]
        })

      return authResult.accessToken
    } catch (error) {
      return null
    }
  }

  public async get<T>(endpoint: string): Promise<T> {
    const accessToken = await this.getAccessToken()
    const url = new URL(endpoint, this.baseUrl)

    if (!accessToken) {
      throw new Error('Access token not available')
    }

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`
    })

    const requestOptions = {
      method: 'GET',
      headers
    }

    try {
      const response = await fetch(url, requestOptions)
      if (response && response.ok) {
        if (response.status === 204) {
          return null as T
        }
        return (await response.json()) as T
      }

      const errorResponse = (await response.json()) as IErrorResponse

      throw new Error(errorResponse.message)
    } catch (error: any) {
      throw new Error(error)
    }
  }

  public async post(endpoint: string, body: any) {
    const accessToken = await this.getAccessToken()
    const url = new URL(endpoint, this.baseUrl)

    if (!accessToken) {
      throw new Error('Access token not available')
    }

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`
    })

    if (!headers.has('Content-Type'))
      headers.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers,
      body: body
    }

    try {
      return await fetch(url, requestOptions)
    } catch (error) {
      throw new Error()
    }
  }

  public async delete(endpoint: string) {
    const accessToken = await this.getAccessToken()
    const url = new URL(endpoint, this.baseUrl)

    if (!accessToken) {
      throw new Error('Access token not available')
    }

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`
    })

    const requestOptions = {
      method: 'DELETE',
      headers
    }

    try {
      const response = await fetch(url, requestOptions)
      if (response && response.ok) {
        return true
      }

      const errorResponse = (await response.json()) as IErrorResponse

      return errorResponse
      
    } catch (error) {
      throw new Error()
    }
  }

  public async uploadFiles(endpoint: string, formData: FormData) {
    const accessToken = await this.getAccessToken()
    const url = new URL(endpoint, this.baseUrl)

    if (!accessToken) {
      throw new Error('Access token not available')
    }

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`
    })

    const requestOptions = {
      method: 'POST',
      headers,
      body: formData
    }

    try {
      const response = await fetch(url, requestOptions)

      return response
    } catch (error) {
      throw new Error()
    }
  }

  public async put(endpoint: string, body: string) {
    const accessToken = await this.getAccessToken()
    const url = new URL(endpoint, this.baseUrl)

    if (!accessToken) {
      throw new Error('Access token not available')
    }

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`
    })

    if (!headers.has('Content-Type'))
      headers.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'PUT',
      headers,
      body: body
    }

    try {
      const response = await fetch(url, requestOptions)

      const status = await response.status

      return status
    } catch (error) {
      throw new Error()
    }
  }

  public async tutorialFetchAuth(url: string, options: any = {}) {
    const accessToken = await this.getAccessToken(true)
    if (!accessToken) {
      throw new Error('Access token not available')
    }
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }

    options.headers = headers
    return fetch(url, options)
  }
}
