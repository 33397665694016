import { TemperatureAlarmName } from './alarmConstants'

export function setCardColor(alarm) {
  const redCard = {
    cardColor: 'r-assetCard-red',
    tagColor: 'ln-u-bg-color-error',
  }
  const greyCard = {
    cardColor: 'r-assetCard-grey',
    tagColor: 'ln-u-bg-color-grey-80',
  }

  if (alarm != null) {
    if (
      alarm.status === 'New' ||
      (alarm.alarmName === TemperatureAlarmName &&
        (alarm.status === 'Pending' || alarm.status === 'Open') &&
        alarm.nextRequiredAction === 'Demerchandise fixture') ||
      ((alarm.status === 'Pending' || alarm.status === 'Open') &&
        alarm.nextRequiredAction === null || alarm.nextRequiredAction === undefined)
    ) {
      return redCard
    }
    return greyCard
  }
  return null
}

export default {
  setCardColor,
}
