import { Card } from '@jsluna/card'
import { Container } from '@jsluna/react'
import { Display1 } from '@jsluna/typography'
import { Label } from '@jsluna/form'

function PackAlarmResolved() {
  return (
    <Container
      id="r-packAlarmResolved"
      className="ln-u-padding-ends*3"
      size="xs"
    >
      <div className="ln-u-padding*2">
        <Display1>Refrigeration pack problem has been resolved</Display1>
      </div>
      <Card>
        <div className="ln-u-padding-bottom*2">
          The <b>pack is working correctly</b> and fixtures within this pack are
          no longer at high risk.
        </div>
        <Label className="ln-u-flush-bottom">You can now:</Label>
        <ul>
          <li>
            <b>Stop</b> taking manual temperature checks
          </li>
          <li>Re-merchandise the fixtures in the pack if necessary</li>
        </ul>
      </Card>
    </Container>
  )
}

export default PackAlarmResolved
