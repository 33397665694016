import { Display3 } from '@jsluna/typography'

const Title = ({ text }: any) => {
  return (
    <div>
      <Display3 id="r-card-title">{text}</Display3>
    </div>
  )
}

export default Title
