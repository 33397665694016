import { useEffect, useRef, useState } from 'react'
import { Container, GridWrapper } from '@jsluna/grid'
import { Card } from '@jsluna/card'
import { Heading4, Display2 } from '@jsluna/typography'
import { ProgressSpinner } from '@jsluna/progress'
import { Pagination } from '../Pagination'
import FixtureCard from '../Cards/FixtureCard'
import useAssets from '../../hooks/useAssets'
import usePage from '../../hooks/usePage'
import { useCurrentStore } from '../../hooks'
import NoFixtureMessage from '../NoFixtureMessage/NoFixtureMessage'

interface FixturesListParams {
  searchQuery: string
}

const FixtureList = ({searchQuery}: FixturesListParams) => {
  const storeCode = useCurrentStore().storeCode
  const pageType = "fixturesPage"
  const {page, handlePage} = usePage(pageType)
  const [assetsResource, assetsLoader] = useAssets()
  const [load, setLoad] = useState<boolean>(false)
  const searchQueryRef = useRef<string | undefined>()
  let assetsData = null
  let pagination = null

  useEffect(() => {
    const tempSearchQuery = searchQueryRef.current
    searchQueryRef.current = searchQuery
    if (
      ((tempSearchQuery !== searchQueryRef.current &&
        searchQuery !== '' &&
        page !== 0) ||
        (searchQuery == '' && page !== 0)) &&
        tempSearchQuery !== undefined
    ) {
      handlePage(pageType, 0)
    } else {
      if (tempSearchQuery !== undefined) load ? setLoad(false) : setLoad(true)
    }
  }, [searchQuery])

  useEffect(() => {
    assetsLoader.current(storeCode, searchQuery, page)
  }, [page, assetsLoader, load])

  if (assetsResource.hasLoaded && !assetsResource.hasError) {
    const responseData = assetsResource.data
    pagination = responseData?.metadata?.pagination
      ? responseData?.metadata
      : undefined
    assetsData = responseData?.results
  }

  const pageTitle = () => {
    return (
      <Container className="ln-u-padding-ends" size="xs">
        <Display2 id="r-fixture" className="r-fixture ln-u-padding*2">
          Fixtures in store
        </Display2>
      </Container>
    )
  }

  if (!assetsResource.hasLoaded && !assetsResource.hasError)
    return (
      <>
        <Container className="r-alarmsList" size="xs">
          {pageTitle()}
          <Card id="r-loadingElement" className="ln-u-text-align-center">
            <ProgressSpinner size="large" />
          </Card>
        </Container>
      </>
    )

  if ((assetsResource.hasError || !assetsData || assetsData?.length <= 0) && searchQuery != '')
    return (
      <>
        {pageTitle()}
        <Container className="r-alarmsList" size="xs">
          <Heading4 className="ln-u-soft-top">No Assets Were Found</Heading4>
        </Container>
      </>
    )

  if ((assetsResource.hasError || !assetsData || assetsData?.length <= 0) && searchQuery == '')
    return (
      <>
        {pageTitle()}
        <Container className="r-alarmsList" size="xs">
          <NoFixtureMessage />
        </Container>
      </>
    )

  if (assetsData)
    return (
      <div className="no-background">
        {pageTitle()}
        <Container>
          <GridWrapper
            matrix
            gutterSize="sm"
            className="ln-o-grid--gutter-zero@max-mobile"
          >
            {assetsData.map((asset: any) => (
              <FixtureCard key={asset.assetNumber} asset={asset} />
            ))}
          </GridWrapper>
        </Container>
        {pagination?.pagination && (
          <Pagination
            handlePagination={handlePage}
            currentPage={pagination.pagination.currentPage}
            lastPage={pagination.pagination.lastPage}
            pageType={pageType}
          />
        )}
      </div>
    )
}

export default FixtureList
