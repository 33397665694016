import { useApiClient } from '../context/AppContext'
import { useCallbackRef, useResourceLoader } from '.'
import IResourceLoader from '../types/IResourceLoader'
import { ILoadImages, FetchImages, IImages } from '../types'
import assetsApi from '../api/assetsApi'

const useImages = (): [IResourceLoader<IImages[]>, ILoadImages] => {
  const apiClient = useApiClient()

  const response = useResourceLoader<IImages[]>('Unable to load alarm guidance')

  const loader = useCallbackRef<FetchImages>(
    async (
      assetNumber: string,
      alarmId: string,
      uploadType: string,
      timeStamp?: string
    ) =>
      response.load(
        async () =>
          await assetsApi.GetImages(
            apiClient,
            assetNumber,
            alarmId,
            uploadType,
            timeStamp
          )
      )
  )

  return [response, loader]
}

export default useImages
