import { Container } from '@jsluna/react'
import { Card } from '@jsluna/card'
import { Body1, Body2 } from '@jsluna/typography'
import { ErrorCircle } from '@jsluna/icons'

function ChangeIsBlockedMessage() {
  return (
    <Container className="ln-u-padding-top" size="xs">
      <Card className="r-request-failing-message ln-u-margin-bottom*2">
        <div className="ln-u-text-align-center ln-u-padding-ends*2">
          <Body2
            id="r-request-failing-header"
            className="ln-u-font-weight-bold"
          >
            <ErrorCircle
              id="r-errorCircle"
              className="r-error-icon ln-u-color-red-80 ln-u-display-3"
            />
            <br />
            Unable to change temperature
          </Body2>
        </div>
        <div id="r-request-completion-text" className="ln-u-padding-ends*2">
          <Body1>
            Please respond to the fixture alarm first and once the fixture
            returns to normal you&apos;ll be able to change the temperature for
            this fixture.
          </Body1>
        </div>
      </Card>
    </Container>
  )
}

export default ChangeIsBlockedMessage
