import { useContext, useState } from 'react'
import { Card } from '@jsluna/card'
import { Container } from '@jsluna/react'
import { Display1, Caption } from '@jsluna/typography'
import { FormGroup, Label } from '@jsluna/form'
import { FilledButton } from '@jsluna/button'
import alarmsApi from '../../../../api/alarmsApi'
import getRelativeDate from '../../../../utils/relativeDate'
import { ProgressSpinner } from '@jsluna/progress'
import WorkOrderLink from '../../WorkOrderLink/WorkOrderLink'
import { AppContext, useApiClient } from '../../../../context/AppContext'
import { IAlarms } from '../../../../types/AlarmsPaginated/IAlarmsPaginated'

interface WaterLeakAlarmParams {
  alarm: IAlarms
  historyEvents: Array<any> | null,
  storeCode: string,
  userName: string,
}

const WaterLeakAlarm = ({ alarm, historyEvents, storeCode, userName }: WaterLeakAlarmParams) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [responseSent, setResponseSent] = useState(false)
  let alarmIsAnswered = false
  let buttonIsDisabled = false
  const [loading, setLoading] = useState(false)
  let user = userName
  let date = new Date()
  const apiClient = useApiClient()
  const appcontext = useContext(AppContext)
  const sendResponse = async () => {
    buttonIsDisabled = true
    setLoading(true)
    const postAlarmResponse = await alarmsApi.PostAlarmResponse(
      apiClient,
      storeCode,
      userName,
      alarm,
      'confirm_action',
      'water leak alarm acknowledged',
    )
    if (!postAlarmResponse?.status) {
      setErrorMessage(postAlarmResponse.message ?? 'Unable to submit the response')
      buttonIsDisabled = false
    } else {
      if (appcontext && appcontext.assets[alarm.assetNumber]) {
        delete appcontext.assets[alarm.assetNumber]
      }
      setResponseSent(true)
    }
    setLoading(false)
  }

  const checkAlarmEvents = () => {
    historyEvents?.forEach(event => {
      if (event?.alarmId === alarm?.id) {
        if (event?.answerType === 'confirm_action') {
          alarmIsAnswered = true
          user = event.user
          date = event.timestamp
          buttonIsDisabled = true
          return true
        }
      }
      return false
    })
  }
  checkAlarmEvents()

  if (loading) {
    return (
      <Container
        className="ln-u-text-align-center ln-u-padding-top*4"
        size="xs"
      >
        <ProgressSpinner />
      </Container>
    )
  }

  return (
    <Container
      id="r-waterLeakAlarmForm"
      className="ln-u-padding-ends*3"
      size="xs"
    >
      <div className="ln-u-padding*2">
        <Display1>Potential water leak</Display1>
      </div>
      <Card>
        <div>
          The listed fixture(s) has a possible water leak, due to an issue with
          the drainage pump.
        </div>
        <Label className="ln-u-soft-top ln-u-flush-bottom">You need to:</Label>
        <ul className="ln-u-soft-bottom">
          <li>Check for pooling water near the fixture</li>
          <li>Make safe and use puddle pythons if needed</li>
        </ul>
        <FormGroup
          name="r-submit-button"
          error={errorMessage}
          className="ln-u-flush-bottom"
        >
          <FilledButton
            id="r-submitButton"
            fullWidth
            disabled={responseSent ? true : buttonIsDisabled}
            onClick={() => sendResponse()}
          >
            Mark as done
          </FilledButton>
          {alarmIsAnswered || responseSent ? (
            <div className="ln-u-margin-top">
              <Caption id="r-tag-reportingDate" className="ln-u-color-grey">
                Acknowledged by {user} on {getRelativeDate(date)}
              </Caption>
            </div>
          ) : null}
        </FormGroup>
        {alarm.woNumber === null || alarm.woNumber === '' ? null : (
          <WorkOrderLink workOrderNumber={alarm.woNumber} />
        )}
      </Card>
    </Container>
  )
}

export default WaterLeakAlarm
