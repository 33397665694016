import { Display3 } from '@jsluna/typography'

interface FixturesNumberParams {
  details: any,
  siteName: string | undefined,
}

const FixtureNumber = ({details, siteName}: FixturesNumberParams) => {
  return (
    <div>
      <Display3 id="r-asset-fixtureNumber" className="ln-u-color-black">
        {details && siteName?.startsWith('P')
          ? `${details?.fixtureNumber} (PFS)`
          : details?.fixtureNumber}
      </Display3>
    </div>
  )
}

export default FixtureNumber
