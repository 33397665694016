import { useApiClient } from '../context/AppContext'
import { useCallbackRef, useResourceLoader } from '.'
import alarmsApi from '../api/alarmsApi'
import IResourceLoader from '../types/IResourceLoader'
import { ILoadAlarms, IAlarms, FetchAlarms } from '../types'

const useAlarms = (
): [IResourceLoader<IAlarms>, ILoadAlarms] => {

  const apiClient = useApiClient()
  const response = useResourceLoader<IAlarms>('Unable to load alarms')

  const loader = useCallbackRef<FetchAlarms>(async(externalId: string, storeCode: string): Promise<IAlarms> => {
      return response.load(async() => alarmsApi.GetAlarms(apiClient, externalId, storeCode))
  })

  return [response, loader]
}

export default useAlarms
