import { useEffect, useState } from 'react'
import { Modal, ModalHeading } from '@jsluna/modal'
import { ProgressSpinner } from '@jsluna/progress'
import { TemperatureAlarmName } from '../../../../utils/alarmConstants'
import useAlarmGuidance from '../../../../hooks/useAlarmGuidance'
import { IAlarmGuidance } from '../../../../types'

interface ImageGuidanceParams {
  alarm: {
    assetNumber: string
    assetDescription: string
    productType: string
    reason: string
    id: string
    lastResponse: string
    responseTime: string
    user: string
    woNumber: string
    status: string
    alarmName: string
    assetDepartment: string
    alarmResponseImages: {
      length: number
    }
    isParentAlarmed: boolean
  }
  showDeMerchandise: boolean
}

const ImageGuidance = ({ alarm, showDeMerchandise }: ImageGuidanceParams) => {
  const [demerchandiseIsOpen, setDemerchandiseIsOpen] = useState(false)
  const [newAlarmIsOpen, setNewAlarmIsOpen] = useState(false)
  const [alarmGuidanceResource, alarmGuidanceLoader] = useAlarmGuidance()
  const [loading, setLoading] = useState(true)
  let alarmGuidanceData: IAlarmGuidance[] | null | undefined = null

  useEffect(() => {
    alarmGuidanceLoader.current(
      showDeMerchandise,
      alarm.productType,
      alarm.assetDescription,
      alarm.assetDepartment
    )
  }, [
    alarmGuidanceLoader,
    showDeMerchandise,
    alarm.productType,
    alarm.assetDescription,
    alarm.assetDepartment
  ])

  if (alarmGuidanceResource.hasLoaded && !alarmGuidanceResource.hasError) {
    alarmGuidanceData = alarmGuidanceResource?.data
  }

  const onImageLoad = () => {
    setLoading(false)
  }

  const images = () => {
    if (alarmGuidanceData && alarmGuidanceData.length > 0) {
      return (
        <>
          {loading && (
            <div className="ln-u-text-align-center">
              <ProgressSpinner size="large" />
            </div>
          )}
          {alarmGuidanceData.map((image: any) => (
            <img
              key="image"
              src={image}
              onLoad={onImageLoad}
              alt=""
              className="modal-images"
            />
          ))}
        </>
      )
    }
    return []
  }

  return (
    <>
      {!showDeMerchandise &&
      alarm.alarmName === TemperatureAlarmName &&
      (alarm.assetDescription.includes('SERVE') ||
        alarm.assetDescription.includes('COLDROOM') ||
        alarm.assetDescription.includes('MULTIDECK') ||
        alarm.assetDescription.includes('DOOR') ||
        alarm.assetDescription.includes('WELL')) ? (
        <button
          type="button"
          className="img-guide-btn-modal ln-u-padding-bottom*2"
          onClick={() => setNewAlarmIsOpen(true)}
        >
          See Example.
        </button>
      ) : null}
      {showDeMerchandise &&
      alarm.alarmName === TemperatureAlarmName &&
      (alarm.assetDescription.includes('MULTIDECK') ||
        alarm.assetDescription.includes('DOOR') ||
        alarm.assetDescription.includes('WELL')) ? (
        <button
          type="button"
          className="img-guide-btn-modal ln-u-padding-bottom*2"
          onClick={() => setDemerchandiseIsOpen(true)}
        >
          See Example.
        </button>
      ) : null}
      <Modal
        small
        handleClose={() => setDemerchandiseIsOpen(false)}
        open={demerchandiseIsOpen}
        headingId="demerchandise-modal"
      >
        <ModalHeading element="h4">
          Example of a good demerchandised fixture image
        </ModalHeading>
        {images()}
      </Modal>

      <Modal
        small
        handleClose={() => setNewAlarmIsOpen(false)}
        open={newAlarmIsOpen}
        headingId="new-alarm-modal"
      >
        <ModalHeading element="h4">Example image(s)</ModalHeading>
        {alarmGuidanceData && images()}
      </Modal>
    </>
  )
}

export default ImageGuidance
