import { SearchField } from '@jsluna/form'
import { Button } from '@jsluna/button'
import { FC } from 'react'

interface StoreSearchProps {
  searchStores: (...props: any) => void
}

const StoreSearch: FC<StoreSearchProps> = ({ searchStores }) => {
  let timeout: any

  const searchChanged = ( value: string) => {
    if (timeout) clearTimeout(timeout)

    timeout = setTimeout(() => {
      if (value.match(/^[0-9]+$/)) {
        searchStores(value,'')
      } else {
        searchStores('', value
        )
      }
    }, 500)
  }
  return (
    <div>
      <SearchField
        id="search-field-1"
        name="search-field-1"
        className="SearchStore"
        placeholder="Search store"
        hasButton
        hasAction
        onChange={({ target }: any) =>
          searchChanged(  target.value)
        }
        action={
          <Button>
            <span className="ln-u-visually-hidden">Search store</span>
          </Button>
        }
      />
    </div>
  )
}


export default StoreSearch
