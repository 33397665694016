import PropTypes from 'prop-types'
import { Body1 } from '@jsluna/typography'

function AssetNumber({ assetNumber }) {
  return (
    <div>
      <Body1
        className="r-asset-assetNumber ln-u-color-grey"
        id="r-asset-assetNumber"
      >
        {assetNumber}
      </Body1>
    </div>
  )
}

AssetNumber.propTypes = {
  assetNumber: PropTypes.string,
}

AssetNumber.defaultProps = {
  assetNumber: null,
}

export default AssetNumber
