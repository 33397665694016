import { PropTypes } from 'prop-types'
import { Container } from '@jsluna/react'
import { Card } from '@jsluna/card'
import { Body2, Caption } from '@jsluna/typography'
import { InfoCircle } from '@jsluna/icons'
import { FilledButton } from '@jsluna/button'
import { useNavigate } from 'react-router-dom'

function CompletedMessage({ message }) {
  const history = useNavigate()
  return (
    <Container className="ln-u-padding-top" size="xs">
      <Card
        id="r-request-completion-message"
        className="r-request-completion-message ln-u-margin-bottom*2"
      >
        <div className="ln-u-text-align-center">
          <Body2
            id="r-request-completion-header"
            className="ln-u-font-weight-bold"
          >
            <InfoCircle
              id="r-info-icon"
              className="r-info-icon ln-u-color-blue ln-u-display-3"
            />
            <br />
            Change completed
          </Body2>
        </div>
        <div id="r-request-completion-text" className="ln-u-padding-ends*2">
          <Caption>{message}</Caption>
        </div>
        <FilledButton id="r-return-button" fullWidth onClick={() => history(-1)}> 
          Back to fixture list
        </FilledButton>
      </Card>
    </Container>
  )
}

CompletedMessage.propTypes = {
  message: PropTypes.string,
}

CompletedMessage.defaultProps = {
  message: null,
}

export default CompletedMessage
