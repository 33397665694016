import PropTypes from 'prop-types'
import { Time } from '@jsluna/icons'
import getRelativeDate from '../../../../../utils/relativeDate'

function Timestamp({ timestamp }) {
  return (
    <div id="r-event-timestamp" className="r-event-timestamp">
      <Time /> {getRelativeDate(timestamp)}
    </div>
  )
}

Timestamp.propTypes = {
  timestamp: PropTypes.string.isRequired,
}

export default Timestamp
