import { FilledButton } from '@jsluna/button'
import { Container } from '@jsluna/react'
import { Card } from '@jsluna/card'
import { Body2, Display1, Caption } from '@jsluna/typography'
import FmAssistUrl from '../../../utils/fmAssistLink'

const RaiseWorkOrderLink = ({assetNumber}: any) => {
  const link = 'raiseWorkOrder'
  return (
    <Container
      id="r-raise-workorder-container"
      className="ln-u-padding-ends"
      size="xs"
    >
      <Display1>
        <div id="r-raise-workorder-display" className="ln-u-padding*2">
          Raise a workorder
        </div>
      </Display1>
      <Card id="r-raise-workorder-card">
        <div className="ln-u-padding">
          <Body2 className="ln-u-font-weight-bold">Spotted a problem?</Body2>
          <div
            className="ln-u-font-weight-bold ln-u-padding-ends"
            id="r-raise-workorder-message"
          >
            <Caption>Report a problem in FM Assist</Caption>
          </div>

          <a
            href={FmAssistUrl(assetNumber, link)}
            rel="noreferrer"
            target="_blank"
          >
            <FilledButton id="r-raise-workorder-link" fullWidth>
              Report a problem
            </FilledButton>
          </a>
        </div>
      </Card>
    </Container>
  )
}

export default RaiseWorkOrderLink
