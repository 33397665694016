import { ErrorCircle } from '@jsluna/icons'
import { Container } from '@jsluna/grid'
import { OutlinedButton } from '@jsluna/button'
import { Body1, Display2 } from '@jsluna/typography'

const NoFixtureMessage = () => {
  return (
    <Container className="r-no-fixture-message ln-u-padding*2">
      <ErrorCircle
        id="r-errorCircle"
        className="ln-u-color-red ln-u-1/1 ln-c-icon--large ln-u-display-4"
      />
      <Display2 className="ln-u-padding-top*3" element="h6">
        We cannot find the fixture list for your store
      </Display2>
      <Body1 element="p">
        Something&apos;s gone wrong along the way and we can&apos;t find the
        fixture list for the store.
      </Body1>
      <Body1 className="ln-u-padding-bottom*2" element="p">
        Please contact the Sainsbury&apos;s Tech Service Desk on 0345 603 2638
        to report the issue.
      </Body1>
      <OutlinedButton fullWidth>0345 603 2638</OutlinedButton>
    </Container>
  )
}

export default NoFixtureMessage
