import { useContext } from "react"
import { AppContext } from "../../context/AppContext"

const NavStoreName = () => {
  const context = useContext(AppContext)
  
  if (context?.appContextData.store) {
    const storeCode = String(context?.appContextData.store?.storeCode).replace(/^[a-z]/i, '')

    if (!context?.appContextData.store) return <span />

    const storeText =
    !context?.appContextData.store?.storeName || context?.appContextData.store?.storeName === ''
        ? `Store ${storeCode}`
        : `${context?.appContextData.store?.storeName} (${storeCode})`

    return <span className="cc-nav-fix">{storeText}</span>
  } else {
    return <></>
  }
}

export default NavStoreName