import { PropTypes } from 'prop-types'
import { Container } from '@jsluna/react'
import { Card } from '@jsluna/card'
import { Body1, Display1 } from '@jsluna/typography'
import { FilledButton } from '@jsluna/button'
import { InfoCircle } from '@jsluna/icons'
import { useNavigate } from 'react-router-dom'
import getRelativeDate from '../../../../utils/relativeDate'

function InProgressMessage({ productTypeChangeData }) {
  const history = useNavigate()
  return (
    <Container className="ln-u-padding-ends" size="xs">
      <Display1>
        <div className="ln-u-padding*2">Request change</div>
      </Display1>
      <Card
        id="r-request-in-progress-message"
        className="r-request-in-progress-message"
      >
        <div className="ln-u-padding-ends*2 ln-u-text-align-center">
          <InfoCircle
            id="r-infoCircle"
            className="r-info-icon ln-u-color-blue ln-u-display-3"
          />
          <br />
          <Body1
            id="r-request-inProgress-header"
            className="ln-u-font-weight-bold"
          >
            Change requested
          </Body1>
        </div>
        <div>
          <Body1
            id="r-previous-product-type"
            className="r-previous-product-type"
          >
            <b>from</b> {productTypeChangeData?.currentProductType}
          </Body1>
        </div>
        <div>
          <Body1 id="r-new-product-type" className="r-new-product-type">
            <b>to</b> {productTypeChangeData?.newProductType}
          </Body1>
        </div>
        <div>
          <Body1 id="r-timestamp" className="r-timestamp ln-u-color-grey">
            {productTypeChangeData?.timestamp
              ? getRelativeDate(productTypeChangeData?.timestamp)
              : null}
          </Body1>
        </div>
        <div id="r-request-inProgress-text" className="ln-u-padding-ends*2">
          <Body1>
            A change of merchandise has been requested. Do not re-stock with the
            requested product until the change is completed.
          </Body1>
        </div>
        <FilledButton id="r-return-button" fullWidth onClick={() => history(-1)}> 
          Back to fixture list
        </FilledButton>
      </Card>
    </Container>
  )
}

InProgressMessage.propTypes = {
  productTypeChangeData: PropTypes.shape({
    currentProductType: PropTypes.string,
    newProductType: PropTypes.string,
    timestamp: PropTypes.string,
  }),
}

InProgressMessage.defaultProps = {
  productTypeChangeData: null,
}

export default InProgressMessage
