import { Link } from 'react-router-dom'
import { GridItem, GridWrapper } from '@jsluna/grid'
import { Card } from '@jsluna/card'
import { ArrowRight } from '@jsluna/icons'
import { setCardColor } from '../../../utils/cardColor'
import Tag from '../Tag'
import FixtureNumber from '../Components/FixtureNumber'
import AssetNumber from '../Components/AssetNumber'
import ProductType from '../Components/ProductType'
import Department from '../Components/Department'
import { useContext } from 'react'
import { AppContext } from '../../../context/AppContext'


interface FixtureCardParams {
  asset: {
    alarms: Array<any>
    assetEventsHistoryResponse: Array<any>
    assetNumber: string
    assetsMonitoring: string
    assetTypeDescription: string
    changeRequest: {
      status: string,
      reason: string,
      timestamp: Date,
    }
    department: string
    isPackParent: boolean
    isSuspended: boolean
    merchandising: {assetNumber: string, fixtureNumber: string, productType: string}
    siteName: string
    status: string
   monitoringStatus: string
  }
}

const FixtureCard = ({asset}: FixtureCardParams) => {
  const context = useContext(AppContext)
  
  if (context?.assets && !(Object.prototype.hasOwnProperty.call(context.assets, asset.assetNumber))) {
    context.assets[asset.assetNumber] = asset
  }
  else{
    if (context) {
      delete context.assets[asset.assetNumber]
    }
  }

  let card : {
    cardColor: string,
    tagColor: string,
  } | null = null
  if (asset && !asset.monitoringStatus && asset.alarms.length > 0) {
    card = setCardColor(asset.alarms[0])
  }

  return (
    <GridItem key={asset && asset.assetNumber} size="10/10">
      <Link to={`/fixturelist/${asset && asset.assetNumber}`} className="a-no-link-style">
        <Card
          className={`r-asset-card ${
            asset && !asset.monitoringStatus && asset.alarms.length > 0 && card
              ? card.cardColor
              : 'r-assetCard-noTag'
          }`}
          id={`asset-${asset.assetNumber}`}
        >
          <GridWrapper verticalAlign="middle">
            <GridItem size="9/10">
              <FixtureNumber
                details={asset && asset.merchandising}
                siteName={asset && asset.siteName}
              />
              <AssetNumber assetNumber={asset && asset.assetNumber} />
              <ProductType details={asset && asset.merchandising} />
              <Department department={asset && asset.department} />
              <Tag
                className=" ln-u-soft-bottoms"
                asset={asset && asset}
                tagColor={card && card.tagColor}
              />
            </GridItem>
            <GridItem size="1/10">
              <ArrowRight size="large" />
            </GridItem>
          </GridWrapper>
        </Card>
      </Link>
    </GridItem>
  )
}

export default FixtureCard
