import { useApiClient } from '../context/AppContext'
import { useCallbackRef, useResourceLoader } from '.'
import alarmsApi from '../api/alarmsApi'
import IResourceLoader from '../types/IResourceLoader'
import { ILoadAlarmsPaginated, IAlarmsPaginated, FetchAlarmsPaginated } from '../types'

const useAlarmsPaginated = (
): [IResourceLoader<IAlarmsPaginated>, ILoadAlarmsPaginated] => {

  const apiClient = useApiClient()
  const response = useResourceLoader<IAlarmsPaginated>('Unable to load alarms')

  const loader = useCallbackRef<FetchAlarmsPaginated>(async(storeCode: string, filters: any, pageNumber: number, alarmType: string): Promise<IAlarmsPaginated> => {
    if (alarmType === "alarmHistory")
      return response.load(async() => alarmsApi.GetAlarmsHistory(apiClient, storeCode, filters, pageNumber))
    else
      return response.load(async() => alarmsApi.GetAlarmsPaginated(apiClient, storeCode, filters, pageNumber, alarmType))
  })

  return [response, loader]
}

export default useAlarmsPaginated
