import { PropTypes } from 'prop-types'
import { Card } from '@jsluna/card'
import { Body2 } from '@jsluna/typography'
import Timestamp from '../ExtraElements/Timestamp'

function AlarmMessage({ alarmEvent }) {
  return (
    <div className="r-event-alarm ln-u-padding-bottom">
      <Card
        className={
          alarmEvent.eventType === 'NEW_ALARM' ? 'r-assetCard-alarm' : ''
        }
      >
        <div id="r-event-header" className="r-event-header ln-u-soft-bottom-sm">
          <Body2 className="ln-u-font-weight-bold">
            {alarmEvent.eventName}
          </Body2>
        </div>
        <Timestamp timestamp={alarmEvent.timestamp} />
      </Card>
    </div>
  )
}

AlarmMessage.propTypes = {
  alarmEvent: PropTypes.shape({
    eventName: PropTypes.string,
    eventType: PropTypes.string,
    timestamp: PropTypes.string,
    user: PropTypes.string,
  }).isRequired,
}

export default AlarmMessage
