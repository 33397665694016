import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Container } from '@jsluna/grid'

function GlobalBar({ element, children, className, label, size, ...rest }) {
  const Element = element

  return (
    <Element
      className={classnames('ln-c-header__global-bar', className)}
      aria-label={label}
      {...rest}
    >
      <Container className="ln-c-header__global-bar-inner" size={size}>
        {children}
      </Container>
    </Element>
  )
}

GlobalBar.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  /** Aria label used to give greater context of navigation to screen reader users */
  label: PropTypes.string,
  size: PropTypes.string,
}

GlobalBar.defaultProps = {
  element: 'div',
  children: undefined,
  className: undefined,
  label: undefined,
  size: undefined,
}

GlobalBar.displayName = 'HeaderGlobalBar'

export default GlobalBar
