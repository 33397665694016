import { useApiClient } from '../context/AppContext'
import { useCallbackRef, useResourceLoader } from '.'
import IResourceLoader from '../types/IResourceLoader'
import { ILoadAlarmGuidance, FetchAlarmGuidance, IAlarmGuidance } from '../types'
import alarmsApi from '../api/alarmsApi'

const useAlarmGuidance = (): [IResourceLoader<IAlarmGuidance[]>, ILoadAlarmGuidance] => {

  const apiClient = useApiClient()

  const response = useResourceLoader<IAlarmGuidance[]>(
    'Unable to load alarm guidance'
  )

  const loader = useCallbackRef<FetchAlarmGuidance>(async(showDeMerchandise:boolean, productType:string, assetDescription:string, assetDepartment:string): Promise<IAlarmGuidance[]> => {
    return response.load(async() => await alarmsApi.GetAlarmsImageGuidance(apiClient, showDeMerchandise, productType, assetDescription, assetDepartment))
  })
  
  return [response, loader]
}

export default useAlarmGuidance
