import { Container } from '@jsluna/react'
import { Pagination as LunaPagination } from '@jsluna/pagination'
import { Card } from '@jsluna/card'

interface PaginationParams {
  currentPage:number,
  lastPage:number,
  handlePagination:(pageType: string, pageNumber?: number | undefined) => void,
  pageType:string
}

const Pagination = ({currentPage, lastPage, handlePagination, pageType}: PaginationParams) =>
  {
    return (
      <Container>
        <Card id="r-pagination-card">
          <LunaPagination
            id="r-pagination-bar"
            className="ln-u-text-align-center"
            showFirstAndLast
            current={currentPage + 1}
            total={lastPage + 1}
            onChange={(page:number, e:any) => {
              e.preventDefault()
              handlePagination(pageType, page - 1)
            }}
          />
        </Card>
      </Container>
    )
  }

  export default Pagination