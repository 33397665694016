import { PropTypes } from 'prop-types'
import Suspension from './Suspension/SuspensionMessage'
import ProductTypeChange from './ProductChange/ProductTypeChange'
import Alarm from './Alarms/AlarmMessage'
import AlarmResponse from './Alarms/AlarmResponseMessage'
import WorkOrderCreatedMessage from './Alarms/WorkOrderMessage'

function HistoryElements({ assetNumber, event }) {
  if (event.eventType === 'NEW_WO' && event.event !== 'PARENT_ALARM') {
    return (
      <WorkOrderCreatedMessage
        id="r-history-workorder"
        event={event}
      />
    )
  }

  if (
    event.event === 'ALARM' &&
    (event.eventType === 'DEMERCHANDISE' || event.eventType === 'RESPONSE')
  ) {
    return (
      <AlarmResponse
        id="r-history-alarm-response"
        assetNumber={assetNumber}
        alarmEvent={event}
      />
    )
  }

  if (event.event === 'ALARM' || event.event === 'PARENT_ALARM') {
    return <Alarm id="r-history-alarm" alarmEvent={event} />
  }

  if (event.event === 'SUSPENSION') {
    return <Suspension id="r-history-suspension" suspensionEvent={event} />
  }

  if (event.event === 'PRODUCT_TYPE_CHANGE') {
    return <ProductTypeChange id="r-history-productTypeChange" event={event} />
  }
  return null
}

HistoryElements.propTypes = {
  assetNumber: PropTypes.string.isRequired,
  event: PropTypes.shape({
    event: PropTypes.string,
    eventType: PropTypes.string,
  }),
}

HistoryElements.defaultProps = {
  event: null,
}

export default HistoryElements
