import { Body1 } from '@jsluna/typography'
import PropTypes from 'prop-types'

function ProductTypeChangeDetails({ event }) {
  return (
    <div id="r-event-product-type-change-details">
      <div>
        <Body1 id="r-previous-product-type" className="r-previous-product-type">
          <b>from</b> {event.currentProductType}
        </Body1>
      </div>
      <div className="ln-u-soft-bottom-sm">
        <Body1 id="r-new-product-type" className="r-new-product-type">
          <b>to</b> {event.newProductType}
        </Body1>
      </div>
    </div>
  )
}

ProductTypeChangeDetails.propTypes = {
  event: PropTypes.shape({
    currentProductType: PropTypes.string,
    newProductType: PropTypes.string,
  }).isRequired,
}

export default ProductTypeChangeDetails
