import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../context/AppContext'
import { IStore } from '../types/Store/IStore'

const nullStore: IStore = {
  storeCode: '',
  storeName: undefined,
  inScope: false
}

const useCurrentStore = (): IStore => {
  const context = useContext(AppContext)

  const [store, setStore] = useState(context && context.appContextData.store!== undefined ? context.appContextData.store : nullStore)

  useEffect(() => {
    if (context && context.appContextData.store !== undefined) {
      setStore(context!.appContextData.store!)
    }
  }, [context!.appContextData.store, store])

  return store
}

export default useCurrentStore
