/* eslint-disable array-callback-return */
import {
  IAssets,
  IAssetsDetails,
  IChildAssets,
  IMerchandisingStatus,
  IImages
} from '../types'
import { IPostProductTypeRequest } from '../types/Assets/IAssets'
import { ApiClient } from './ApiClient'
import webApi from './webApi'

const GetAssets = async (
  apiClient: ApiClient,
  storeCode: string,
  filters?: string,
  pageNumber?: number
): Promise<IAssets> => {
  const response = await webApi.get<IAssets>(apiClient, {
    url: '/api/assets',
    params: { siteName: storeCode, filters: filters, pageNumber: pageNumber}
  })
  return response
}

const GetAssetDetails = async (
  apiClient: ApiClient,
  assetNumber: string,
  storeCode: string
): Promise<IAssetsDetails> => {
  const response = await webApi.get<IAssetsDetails>(apiClient, {
    url: `/api/assets/${assetNumber}`,
    params: { siteName: storeCode }
  })
  return response
}

const GetChildAssets = async (
  apiClient: ApiClient,
  assetNumber: string,
  alarmId: string
): Promise<IChildAssets[]> => {
  const response = await webApi.get<IChildAssets[]>(apiClient, {
    url: `/api/assets/${assetNumber}/${alarmId}`,
    params: []
  })
  return response
}

const GetMerchandisingStatus = async (
  apiClient: ApiClient,
  assetNumber: string
): Promise<IMerchandisingStatus[]> => {
  const response = await webApi.get<IMerchandisingStatus[]>(apiClient, {
    url: `/api/merchandising/${assetNumber}/status`,
    params: []
  })
  return response
}

const GetImages = async (
  apiClient: ApiClient,
  assetNumber: string,
  alarmId: string,
  uploadType: string,
  timestamp = 'none'
): Promise<IImages[]> => {
  const response = await webApi.get<IImages[]>(apiClient, {
    url: `/api/images/${assetNumber}/${alarmId}/${uploadType}/${timestamp}`,
    params: []
  })
  return response
}

const DeleteImage = async (
  apiClient: ApiClient,
  assetNumber: string,
  alarmId: string,
  imageName: string
) => {
  const response = await webApi.deleteImage(
    apiClient,
    `/api/images/${assetNumber}/${alarmId}/${imageName}`
  )

  if (response)
    return {
      error: false
    }

  return { error: true, errorMessage: response }
}

const UploadFiles = async (
  apiClient: ApiClient,
  assetNumber: string,
  alarmId: string,
  uploadType: string,
  { ...files } = {}
) => {
  const fd = new FormData()
  let totalSize = 0
  Object.entries(files).map(([, value]: [any, any]) => {
    totalSize += value.size
    fd.append(value.name, value)
  })
  if (totalSize > 20971520) {
    return {
      error: true,
      errorMessages: 'File size exceeds 20MB, please upload smaller file(s)'
    }
  }
  const response = await webApi.uploadFiles(
    apiClient,
    `/api/images/${assetNumber}/${alarmId}/${uploadType}`,
    fd
  )
  const responseData = await response.json()

  if (response.status === 200) {
    if (responseData?.errorMessages?.length > 0)
      return {
        error: true,
        uploadedImagesCount: responseData.uploadedImagesCount ?? 0,
        errorMessages: responseData.errorMessages.join(' ; ')
      }
    return {
      error: false,
      uploadedImagesCount: responseData.uploadedImagesCount ?? 0
    }
  }
  return {
    error: true,
    uploadedImagesCount: 0,
    errorMessages: responseData.Message
  }
}

const PostProductTypeRequest = async (
  apiClient: ApiClient,
  fixtureDetails: any,
  newProductType: string
): Promise<IPostProductTypeRequest> => {
  const data = {
    siteName:
      fixtureDetails.siteName.length > 4
        ? fixtureDetails.siteName.substring(1)
        : fixtureDetails.siteName,
    assetNumber: fixtureDetails.assetNumber,
    currentProductType: fixtureDetails.productType,
    newProductType
  }

  return await webApi.put(apiClient, {
    url: `/api/merchandising/${fixtureDetails.assetNumber}`,
    params: [],
    body: JSON.stringify(data)
  })
}

const assetsApi = {
  GetAssets,
  GetAssetDetails,
  GetMerchandisingStatus,
  PostProductTypeRequest,
  GetImages,
  GetChildAssets,
  DeleteImage,
  UploadFiles
}

export default assetsApi
