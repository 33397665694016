import {
  LeakAlarmName,
  PackAlarmName,
  WaterLeakAlarmName,
} from '../utils/alarmConstants'

const WaterLeakAlarmText = {
  title: 'Potential water leak in store',
  description: 'Check your refrigeration fixtures for water leaks',
}

const PackAlarmText = {
  title: 'Refrigeration pack alarm',
  description: 'All fixtures linked to this pack alarm will be affected',
}

const LeakAlarmText = alarm => {
  const title = 'Refrigerant gas leak in store'
  const description = `Check gas detection panel in the ${alarm.assetDepartment?.toLowerCase()}`
  return {
    title,
    description,
  }
}

const checkAlarmType = alarm => {
  switch (alarm.alarmName) {
    case WaterLeakAlarmName:
      return WaterLeakAlarmText
    case PackAlarmName:
      return PackAlarmText
    case LeakAlarmName:
      return LeakAlarmText(alarm)
    default:
      return null
  }
}

export default checkAlarmType
