import { useCurrentStore } from "../hooks"

const FMAssistEnv = () => {
  switch (window.location.host) {
    case 'localhost:3000':
      return 'localhost:3001'
    case 'refrigeration-preprod.retail-azure.js-devops.co.uk':
      return 'https://fmassist-preprod.retail-azure.js-devops.co.uk'
    case 'refrigeration.sainsburys.co.uk':
      return 'https://fmassist.sainsburys.co.uk'
    default:
      return 'default'
  }
}

const FmAssistUrl = (property: string, link: string) => {
  const storeNumber = useCurrentStore().storeCode
  const storeName = useCurrentStore().storeName

  if (link === 'viewWorkOrder') {
    const workOrderNumber = property.replace(/[WO#% ]/g, '')
    return `${FMAssistEnv()}/problem_overview/${workOrderNumber}?app=refrigeration&storeNumber=${storeNumber}&storeName=${storeName}`
  }
  if (link === 'raiseWorkOrder') {
    return `${FMAssistEnv()}/report_problem?app=refrigeration&tag=${property}&storeNumber=${storeNumber}&storeName=${storeName}`
  }
}

export default FmAssistUrl
