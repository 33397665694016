import { PropTypes } from 'prop-types'
import { Container } from '@jsluna/react'
import { Display3 } from '@jsluna/typography'
import { Card } from '@jsluna/card'
import HistoryElements from './HistoryElements'

function FixtureHistory({ assetNumber, events }) {
  return (
    <Container id="r-assetHistory" className="ln-u-padding-ends*3" size="xs">
      <div className="ln-u-padding-bottom">
        <Display3 id="r-history-title" className="r-page-title">
          Last 4 weeks
        </Display3>
      </div>
      {events?.length === 0 ? (
        <Card id="r-no-history">
          <div>
            There has been no activity on this asset in the past 4 weeks.
          </div>
        </Card>
      ) : (
        <div>
          {events && events.map((event, index) => (
            <HistoryElements
              id="r-history-card"
              key={index}
              assetNumber={assetNumber}
              event={event}
            />
          ))}
        </div>
      )}
    </Container>
  )
}

FixtureHistory.propTypes = {
  assetNumber: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default FixtureHistory
