import { useEffect, useRef, useState } from 'react'
import { Container, GridWrapper } from '@jsluna/react'
import { Card } from '@jsluna/card'
import { ProgressSpinner } from '@jsluna/progress'
import { Display3 } from '@jsluna/typography'
import AlarmCard from '../Cards/AlarmCard'
import { Pagination } from '../Pagination'
import ErrorMessage from '../ErrorMessage'
import useAlarmsPaginated from '../../hooks/useAlarmsPaginated'
import usePage from '../../hooks/usePage'
import { useCurrentStore } from '../../hooks'

interface FixtureAlarmsParams {
  filters: any,
}

const FixtureAlarms = ({filters}: FixtureAlarmsParams) => {
  const storeCode = useCurrentStore().storeCode
  const pageType = "alarmOverviewPage"
  const {page, handlePage} = usePage(pageType)
  const [alarmsResource, alarmsLoader] = useAlarmsPaginated()
  const [load, setLoad] = useState<boolean>(false)
  const filtersLength = useRef()
  let alarmsData = null
  let pagination = null

  useEffect(() => {
    const tempFiltersLength = filtersLength.current
    filtersLength.current = filters.length
    if (
      tempFiltersLength !== filtersLength.current &&
      page !== 0 &&
      tempFiltersLength !== undefined
    ) {
      handlePage(pageType, 0)
    } else {
      if (tempFiltersLength !== undefined) load ? setLoad(false) : setLoad(true)
    }
  }, [filters])

  useEffect(() => {
      alarmsLoader.current(storeCode, filters, page, "fixture")
  }, [alarmsLoader, page, load])

  if(alarmsResource.hasLoaded && !alarmsResource.hasError){
    const responseData = alarmsResource.data
    pagination = ((responseData?.metadata?.pagination) ? responseData?.metadata : undefined)
    alarmsData = responseData?.results
  }

  const pageTitle = () => {
    return (
      <Container className="r-pageName ln-u-padding-ends*2" size="xs">
        <Display3 id="r-page-title" className="r-page-title">
            Fixture Alarms
        </Display3>
      </Container>
    )
  }

if(!alarmsResource.hasLoaded && !alarmsResource.hasError) return (
  <Container className="r-alarmsList" size="xs">
    {pageTitle()}
    <Card id="r-loadingElement" className="ln-u-text-align-center">
        <ProgressSpinner size="large" />
    </Card>
  </Container>
)


if(alarmsResource.hasError && filters?.length > 0) return (
  <>
    {pageTitle()}
    <Container className="r-alarmsList" size="xs">
      <ErrorMessage errorMessage="There's no alarms which match your filter." />
    </Container>
  </>
)

if(alarmsResource.hasError && 
  (!alarmsData || alarmsData?.length <= 0) &&
  filters?.length === 0) return (
    <>
      {pageTitle()}
      <Container className="r-alarmsList" size="xs">
        <ErrorMessage errorMessage="No Current Fixture Alarms" />
      </Container>
    </>
  )

  if (alarmsData)
  return (
    <>
      {pageTitle()}
      <Container className="r-alarmsList" size="xs">
        <GridWrapper
          matrix
          gutterSize="sm"
          className="ln-o-grid--gutter-zero@max-mobile"
        >
          {alarmsData.map((fixtureAlarm: any, index) => (
            <AlarmCard key={index}
              alarm={fixtureAlarm} modelType="alarmDetails" />
          ))}
        </GridWrapper>
      </Container>

      {pagination?.pagination && (
        <Pagination
          handlePagination={handlePage}
          currentPage={pagination.pagination.currentPage}
          lastPage={pagination.pagination.lastPage}
          pageType={pageType}
        />
      )}
    </>
  )
}

export default FixtureAlarms
