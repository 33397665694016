import { Card } from '@jsluna/card'
import { Container } from '@jsluna/react'
import { Display1 } from '@jsluna/typography'
import { Label } from '@jsluna/form'

function FixtureCommunicationAlarmResolved() {
  return (
    <Container
      id="r-communicationAlarmResolved"
      className="ln-u-padding-ends*3"
      size="xs"
    >
      <div className="ln-u-padding*2">
        <Display1>Temperature monitoring has been restored</Display1>
      </div>
      <Card>
        <div className="ln-u-padding-bottom*2">
          The listed fixture(s) is now <b>being remotely monitored</b> again, so
          you&apos;ll receive normal refrigeration alarms for this fixture.
        </div>
        <Label className="ln-u-flush-bottom">You can now:</Label>
        <ul>
          <li>
            <b>Stop</b> taking manual temperature checks
          </li>
          <li>Re-merchandise the fixtures if necessary</li>
        </ul>
      </Card>
    </Container>
  )
}

export default FixtureCommunicationAlarmResolved
