import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { PublicClientApplication } from "@azure/msal-browser";

import { MsalProvider } from '@azure/msal-react';

import { AppProvider } from './context/AppContext';
import { config } from './config';

import App from './App';

const msalConfig = {
  auth: {
    clientId: config?.clientId ?? '73315a8d-f440-44e9-bca9-5571a35239cb',
    authority: config?.clientAuthority ?? 'https://login.microsoftonline.com/b2eb8529-51f8-4d43-92bf-85201eea6023',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const page = {
  alarmOverviewPage: 0,
  alarmHistoryPage: 0,
  fixturesPage: 0,
}

root.render(
  <Router>
    <MsalProvider instance={msalInstance}>
      <AppProvider
        apiScope={config?.scope ?? 'api://3b080c8e-9e07-4a35-a5cd-ab1c098d9f3b/.default'}
        tutorialServiceScope={config.tutorialServiceScope ?? 'api://ed8ebfbf-7dfa-4fdb-9f89-288bcce5568c/.default'}
        apiBaseUrl={config?.apiBaseUrl ?? ''}
        search={null}
        page={page}
        assets={{}}
        siteCommunicationAlarm={{}}
        siteAlarmHistory={{}}
      >
        <App />
      </AppProvider>
    </MsalProvider>
  </Router>
)
