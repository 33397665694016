import { PropTypes } from 'prop-types'
import { Card } from '@jsluna/card'
import { Body1, Body2 } from '@jsluna/typography'
import Timestamp from '../ExtraElements/Timestamp'

function SuspensionMessage({ suspensionEvent }) {
  return (
    <div className="r-event-suspension ln-u-padding-bottom">
      <Card
        className={
          suspensionEvent.eventType === 'NEW_SUSPENSION'
            ? 'r-card-suspension'
            : ''
        }
      >
        <div id="r-event-header" className="r-event-header ln-u-soft-bottom-sm">
          <Body2 className="ln-u-font-weight-bold">
            {suspensionEvent.eventName}
          </Body2>
        </div>
        {suspensionEvent.eventType === 'NEW_SUSPENSION' ? (
          <div className="r-event-message-body ln-u-soft-bottom-sm">
            <Body1>{suspensionEvent.suspensionComment}</Body1>
          </div>
        ) : null}
        <Timestamp timestamp={suspensionEvent.timestamp} />
      </Card>
    </div>
  )
}

SuspensionMessage.propTypes = {
  suspensionEvent: PropTypes.shape({
    eventName: PropTypes.string,
    eventType: PropTypes.string,
    timestamp: PropTypes.string,
    suspensionComment: PropTypes.string,
  }).isRequired,
}

export default SuspensionMessage
