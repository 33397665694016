import FmAssistUrl from '../../../utils/fmAssistLink'

const WorkOrderLink = ({workOrderNumber}: any) => {
  const link = 'viewWorkOrder'
  return (
    <div className="ln-u-text-align-center ln-u-margin-ends*2 ">
      <a
        id="r-woLink"
        href={FmAssistUrl(workOrderNumber, link)}
        target="_blank"
        rel="noreferrer"
      >
        <b>View work order</b>
      </a>
    </div>
  )
}

export default WorkOrderLink
