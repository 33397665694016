import { GridItem, GridWrapper } from '@jsluna/grid'
import { Card } from '@jsluna/card'
import { Link } from 'react-router-dom'
import { ArrowRight } from '@jsluna/icons'
import {
  LeakAlarmName,
  PackAlarmName,
  SiteCommunicationAlarmName,
} from '../../../utils/alarmConstants'
import { setCardColor } from '../../../utils/cardColor'
import Tag from '../Tag'
import Department from '../Components/Department'
import AssetNumber from '../Components/AssetNumber'
import FixtureNumber from '../Components/FixtureNumber'
import Description from '../Components/Description'
import Title from '../Components/Title'
import AlarmHistoryLog from '../Components/AlarmHistoryLog'
import NextAction from '../Components/NextAction'
import { IAlarmsPaginated } from '../../../types'

interface AlarmCardParams {
  alarm: IAlarmsPaginated["results"][0] | undefined | any
  modelType: string
}

const AlarmCard = ({ alarm, modelType }: AlarmCardParams) => {
  const showLink = () => {
    return alarm.assetNumber !== null && alarm.assetNumber !== ''
  }

  const getMessage = () => {
    if (alarm.alarmName === PackAlarmName) return 'Refrigeration pack'
    if (alarm.alarmName === LeakAlarmName || alarm.productType == null)
      return alarm.assetDescription
    return alarm.productType
  }

  let card : {
    cardColor: string,
    tagColor: string,
  } | null = null
  if (alarm.status !== 'Closed') {
    card = setCardColor(alarm)
  }

  const getAlarmCard = () => {
    return (
      <Card
        id={
          modelType === 'alarmDetails'
            ? `r-alarm-card-${alarm.id}`
            : 'r-alarmHistory-card'
        }
        className={`r-alarm-card ${
          alarm.status !== 'Closed' ? card && card.cardColor : 'r-assetCard-noTag'
        }`}
      >
        <GridWrapper verticalAlign="middle">
          <GridItem size="9/10">
            {alarm.alarmName === SiteCommunicationAlarmName ? (
              <div id="r-asset-site-alarm">
                <Title
                  text={
                    alarm.siteName[0] === 'P'
                      ? 'Petrol station communication alarm'
                      : 'Site communication alarm'
                  }
                />
                <Description text="All fixtures are affected" />
              </div>
            ) : (
              <div>
                <FixtureNumber details={alarm} siteName={alarm.siteName} />
                <AssetNumber assetNumber={alarm.assetNumber} />
                <Description text={getMessage()} />
                <Department department={alarm?.assetDepartment} />
              </div>
            )}
            {alarm.status === 'Closed' ? null : (
              <Tag
                className=" ln-u-soft-bottoms"
                alarm={alarm}
                isAlarmAuditLog={modelType === 'alarmHistory'}
                isAlarmsPage={modelType === 'alarmDetails'}
                tagColor={card && card.tagColor}
              />
            )}
            <AlarmHistoryLog alarm={alarm} modelType={modelType} />
            <NextAction
              alarm={alarm}
              tagColor={card && card.cardColor}
              modelType={modelType}
            />
          </GridItem>
          {showLink() ? (
            <GridItem size="1/10">
              <ArrowRight size="large" />
            </GridItem>
          ) : null}
        </GridWrapper>
      </Card>
    )
  }

  return (
    <GridItem size="10/10" key={alarm.id}>
      {showLink() ? (
        <Link to={`/fixturelist/${alarm.assetNumber}`} className="a-no-link-style">
          {getAlarmCard()}
        </Link>
      ) : (
        getAlarmCard()
      )}
    </GridItem>
  )
}

export default AlarmCard
