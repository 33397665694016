import { useApiClient } from '../context/AppContext'
import { useCallbackRef, useResourceLoader } from '.'
import assetsApi from '../api/assetsApi'
import IResourceLoader from '../types/IResourceLoader'
import { ILoadAssets, IAssets, FetchAssets } from '../types'

const useAssets = (): [IResourceLoader<IAssets>, ILoadAssets] => {

  const apiClient = useApiClient()

  const response = useResourceLoader<IAssets>(
    'Unable to load assets'
  )

  const loader = useCallbackRef<FetchAssets>(async(storeCode: string, filters?: string, pageNumber?: number): Promise<IAssets> => {
    return response.load(async() => await assetsApi.GetAssets(apiClient, storeCode, filters, pageNumber))
  })

  return [response, loader]
}

export default useAssets
