import { useApiClient } from '../context/AppContext'
import { useCallbackRef, useResourceLoader } from '.'
import assetsApi from '../api/assetsApi'
import IResourceLoader from '../types/IResourceLoader'
import { ILoadAssetsDetails, IAssetsDetails, FetchAssetsDetails } from '../types'

const useAssetsDetails = (): [IResourceLoader<IAssetsDetails>, ILoadAssetsDetails] => {

  const apiClient = useApiClient()

  const response = useResourceLoader<IAssetsDetails>(
    'Unable to load assets'
  )

  const loader = useCallbackRef<FetchAssetsDetails>(async(assetNumber: string, storeCode: string): Promise<IAssetsDetails> => {
    return response.load(async() => await assetsApi.GetAssetDetails(apiClient, assetNumber, storeCode))
  })
  
  return [response, loader]
}

export default useAssetsDetails
