import { PropTypes } from 'prop-types'
import { Body1, Caption } from '@jsluna/typography'
import getRelativeDate from '../../../utils/relativeDate'

function Tag({ asset, alarm, isAlarmAuditLog, isAlarmsPage, tagColor }) {
  let tagMessage = ''
  let dateOpened = ''
  let woTag = false

  const setTagMessage = data => {
    switch (data) {
      case 'New':
        tagMessage = 'New Alarm'
        break
      default:
        tagMessage = 'Open Alarm'
    }
  }

  if (asset != null) {
    if (asset.isSuspended && tagColor == null) {
      tagMessage = 'Suspended'
    } else if (asset.alarms[0] != null) {
      dateOpened = asset.alarms[0].dateOpened
      setTagMessage(asset.alarms[0].status)
      if (asset?.alarms.some(wo => wo.woNumber !== null && wo.woNumber !== ''))
        woTag = true
    } else {
      return null
    }
  }
  if (alarm != null) {
    if (alarm.woNumber !== null && alarm.woNumber !== '') woTag = true
    dateOpened = alarm.dateOpened
    setTagMessage(alarm.status)
  }

  return (
    <div className="ln-u-margin-top">
      <Body1
        id="r-tag-message"
        className={`r-tag ln-u-padding ln-u-color-white ${
          tagMessage === 'Suspended' ? 'ln-u-bg-color-grey-80' : tagColor
        } }`}
      >
        {tagMessage}
      </Body1>
      {!woTag ? null : (
        <Body1
          id="r-tag-message"
          className={`r-tag ln-u-padding ln-u-color-white ${tagColor}`}
          style={{ margin: '10px' }}
        >
          Work order
        </Body1>
      )}
      <div className="ln-u-margin-top">
        {isAlarmAuditLog || isAlarmsPage ? null : (
          <Caption id="r-tag-reportingDate" className="ln-u-color-grey">
            Reported on&nbsp;
            {asset?.isSuspended
              ? getRelativeDate(asset.assetsMonitoring.scheduledSuspendDate)
              : getRelativeDate(dateOpened)}
          </Caption>
        )}
      </div>
    </div>
  )
}

Tag.propTypes = {
  asset: PropTypes.shape(),
  alarm: PropTypes.shape(),
  isAlarmAuditLog: PropTypes.bool,
  isAlarmsPage: PropTypes.bool,
  tagColor: PropTypes.string,
}

Tag.defaultProps = {
  asset: null,
  alarm: null,
  isAlarmAuditLog: false,
  isAlarmsPage: false,
  tagColor: null,
}

export default Tag
