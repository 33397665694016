import { useApiClient } from '../context/AppContext'
import IResourceLoader from '../types/IResourceLoader'
import { useCallbackRef, useResourceLoader } from '.'
import { FetchStoresList } from '../types/StoresList/FetchStoresList'
import storesApi from '../api/storesApi'
import { IDetails } from '../types/Store/IStoreDetails'
import { ILoadStoresList } from '../types/StoresList/ILoadStoresList'

const useStoresListLoader = (): [
  IResourceLoader<IDetails[]>,
  ILoadStoresList
] => {
  const apiClient = useApiClient()

  const resource = useResourceLoader<IDetails[]>('Unable to load stores list')

  const loader = useCallbackRef<FetchStoresList>(() =>
    resource.load(async () => storesApi.getStores(apiClient))
  )

  return [resource, loader]
}

export default useStoresListLoader
