import { useState } from "react";
import filtering from "../utils/filtering";

const useFilters = () => {
    const [filters, setFilter] = useState<Array<any>>([]);
  
    const handleFilters = (filtersList:Array<any>, action:any, filter?:any) => {
        setFilter(filtering(filtersList, action, filter))
    }

  return [filters, handleFilters]
}

export default useFilters;