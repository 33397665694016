import { IDetails, IStoreDetails } from '../types/Store/IStoreDetails'
import { IStoresList } from '../types/StoresList/IStoresList'
import { ApiClient } from './ApiClient'
import webApi from './webApi'

const defaultStoreTypes = 'main,local,pfs,restaurant'

const getStore = async (
  apiClient: ApiClient,
  code: string
): Promise<IDetails> => {

  const response = await webApi.get<IStoreDetails>(apiClient, {
    url: '/api/storelocator/store',
    params: { storeCode: code }
  })

  return response ? response.store : { code }
}

const getStores = async (
  apiClient: ApiClient,
  query: any | undefined = '',
  lat: number | undefined = 0,
  lon: number | undefined = 0,
  storeTypeFilter: string = defaultStoreTypes
): Promise<IDetails[]> => {
  const response = await webApi.get<IStoresList>(apiClient, {
    url: '/api/storelocator/stores',
    params: { store_type: storeTypeFilter, lat: lat, lon: lon, complete: query }
  })

  return response.results
}

const storesApi = {
  getStore,
  getStores
}

export default storesApi
