import PropTypes from 'prop-types'
import { Body1 } from '@jsluna/typography'

function NextAction({ alarm, tagColor, modelType }) {
  if (
    modelType === 'alarmHistory' ||
    (alarm && alarm.nextRequiredAction === null) ||
    alarm.nextRequiredAction === undefined
  )
    return null

  return (
    <div>
      <Body1
        id="r-nextRequiredAction"
        style={{
          color: `${tagColor === 'r-assetCard-red' ? '#d50000' : '#404040'}`,
        }}
      >
        <b>{alarm.nextRequiredAction}</b>
      </Body1>
    </div>
  )
}

NextAction.propTypes = {
  alarm: PropTypes.shape({
    nextRequiredAction: PropTypes.string,
  }),
  tagColor: PropTypes.string,
  modelType: PropTypes.string,
}

NextAction.defaultProps = {
  alarm: null,
  tagColor: null,
  modelType: null,
}

export default NextAction
