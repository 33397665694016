import { useEffect } from 'react'
import { useApiClient } from '../context/AppContext'
import { useCallbackRef, useResourceLoader } from '.'
import assetsApi from '../api/assetsApi'
import IResourceLoader from '../types/IResourceLoader'
import { ILoadMerchandisingStatus, IMerchandisingStatus, FetchMerchandisingStatus } from '../types'

const useMerchandisingStatus = (
  assetNumber: string
): [IResourceLoader<IMerchandisingStatus[]>, ILoadMerchandisingStatus] => {

  const apiClient = useApiClient()

  const response = useResourceLoader<IMerchandisingStatus[]>(
    'Unable to load assets'
  )

  const loader = useCallbackRef<FetchMerchandisingStatus>(async(assetNumber: string): Promise<IMerchandisingStatus[]> => {
    return response.load(async() => await assetsApi.GetMerchandisingStatus(apiClient, assetNumber))
  })

  useEffect(() => {
    loader.current(assetNumber)
  }, [assetNumber])
  

  return [response, loader]
}

export default useMerchandisingStatus;
